import React from "react";
import { IdLink } from "../id-link";
import { Loading } from "../loading";
import "./style.scss";
import { ID_URLS } from "../../const";
import { formatDate } from "../../util/labels";
import { formatFieldToTitle } from "../../util/strings";
export interface JsonBlobProps {
  blob: any | null;
  link?: string;
}

const formatData = (key: string, d: any): string | JSX.Element | null => {
  switch (typeof d) {
    case "string":
      const link = ID_URLS[key];
      if (link !== undefined) {
        return <IdLink id={d} url={link} />;
      }
      const n = Number(d);
      if (!isNaN(n) && n.toString() === d) {
        if (
          (key.endsWith("_ts") || key.endsWith("_at")) &&
          n > 1400000000000 &&
          n < 1800000000000
        ) {
          return formatDate(d);
        }
      }
      return d;
    case "number":

    case "boolean":
    case "bigint":
      return <pre>{d.toString()}</pre>;
    case "function":
      return "func()";
    case "object":
      return <pre style={{ fontSize: 9 }}>{JSON.stringify(d, null, 2)}</pre>;
  }
  return " - ";
};

export const JsonBlob = (props: JsonBlobProps) => {
  const { blob } = props;
  if (blob === null || blob === undefined) {
    return <Loading />;
  }
  const keys = Object.keys(blob);
  return (
    <div className="json-blob mt-4">
      <table className="table table-sm" style={{ fontSize: 12 }}>
        <tbody>
          {keys.map((key) => (
            <tr key={key}>
              <th>{formatFieldToTitle(key)}</th>
              <td
                style={{
                  overflow: "auto",
                  maxWidth: "100%",
                  display: "block",
                }}
              >
                {formatData(key, blob[key])}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
