import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./logo192.png";
import { MenuItem } from "./types";
import "./style.scss";
import classNames from "classnames";
import { Divider } from "../divider";
export interface SideBarProps {
  items: Array<MenuItem>;
}
export const SideBar = (props: SideBarProps) => {
  return (
    <div className="sidebar navbar-nav bg-gradient-primary sidebar sidebar-dark accordion">
      <div className="sidebar-brand-container">
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/"
        >
          <div className="sidebar-brand-icon">
            <img src={logo} alt="Logo" width="39" />
          </div>
          <div className="sidebar-brand-text mx-3">QEX B2B</div>
        </a>
        <Divider />
      </div>
      <div className="nav-item">
        {props.items.map((i, idx) => (
          <TopMenuItem item={i} key={idx} />
        ))}
      </div>
    </div>
  );
};

const TopMenuItem = (props: { item: MenuItem }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="top-menu-item">
        <Link to={props.item.url as string}>
          <span className="nav-click" onClick={() => setOpen(!open)}>
            {props.item.icon} {props.item.label}
            {props.item.items !== undefined ? (
              <i
                className={classNames(
                  "fas fa-chevron-down fa-pull-right menu-chev",
                  {
                    open,
                  }
                )}
              ></i>
            ) : null}
          </span>
        </Link>
      </div>
      <div
        className={`sub-menu ${
          open && props.item.items !== undefined ? "active" : ""
        }`}
      >
        {open && props.item.items !== undefined
          ? props.item.items.map((i, index) => (
              <Link
                className="sub-menu-item"
                to={i.url}
                key={`${i.label}-${index}`}
              >
                {i.label}
              </Link>
            ))
          : null}
      </div>
      <Divider />
    </>
  );
};
