import React, { useEffect, useState } from "react";
import { Card } from "../../components/card";
import { LinkableTable } from "../../components/linkable-table";
import { AdminGroup, Column } from "../../domain";
import { api } from "../../util/api";
import { Loading } from "../../components/loading";
import { GroupForm } from "../group-detail-view/group-form";
const groupColumns: Array<Column> = [
  {
    header: "Label",
    key: "label",
  },
];
export const GroupsView = () => {
  const [adminGroups, setAdminGroups] = useState<Array<AdminGroup> | null>(
    null
  );

  const [createGroupForm, setCreateGroupForm] = useState<boolean>(false);

  const [onSaveGroupForm, setOnSaveGroupForm] = useState<boolean>(false);

  useEffect(() => {
    api.getGroups().then((g: AdminGroup[]) => {
      setAdminGroups(g);
    });
    if (onSaveGroupForm) {
      setOnSaveGroupForm(false);
    }
  }, [onSaveGroupForm]);
  return (
    <div className="partner-summaries">
      {createGroupForm && (
        <GroupForm
          title="Create a new group"
          adminGroup={null}
          onCancel={() => setCreateGroupForm(false)}
          onSave={() => setOnSaveGroupForm(true)}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <Card label={`Admin Groups`}>
            <div className="mb-4 text-right">
              <button
                className="btn btn-dark"
                onClick={() => {
                  setCreateGroupForm(true);
                }}
              >
                Create group

              </button>
            </div>
            {adminGroups === null ? (
              <Loading />
            ) : (
              <LinkableTable
                idUrl="/admin/group/:id"
                idField="id"
                data={adminGroups}
                columns={groupColumns}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
