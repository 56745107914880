import React, { ChangeEvent } from "react";
import { Column, CurrencyDetail } from "../../../domain";

export interface SortableTableRowProps {
  column: Column;
  value: any;
  data: any;
  handleUnChecked: (event: ChangeEvent<HTMLInputElement>, props: any) => void;
}

const SortableRowCheckbox = (props: SortableTableRowProps) => {
  const name = `checkbox-${props.column.key}-${props.value}`;
  const { additionalField } = props.column;
  const field = additionalField ? props.data[additionalField] : null;
  const handle = (e: any, props: any) => {
    props.handleUnChecked(e, props);
  };
  return (
    <input
      type="checkbox"
      key={name}
      onChange={(e) => handle(e, props)}
      value={props.value}
      data-label={field}
      id={`row-${props.data.id}`}
      checked={props.data.selected}
      name={name}
    ></input>
  );
};

export const SortableRowInputs = (props: SortableTableRowProps) => {
  switch (props.column.type) {
    case "CHECKBOX":
      return SortableRowCheckbox(props);
    default:
      // TODO: INPUT, SELECT ....
      return null;
  }
};
