import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";
import { Column } from "../../../domain";
import { Loading } from "../../loading";
import { SortableTableBody } from "./sortable-table-body";
import { SortableTableFooter, SortableTableFooterProps } from "./sortable-table-footer";
import { SortableTableHeader } from "./sortable-table-header";
import { SearchData } from "./types";

export interface SortableTableProps {
  data: Array<any> | null;
  columns: Array<Column>;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  iconDesc?: string;
  iconAsc?: JSX.Element;
  iconBoth?: JSX.Element;
  bottomPagination?: SortableTableFooterProps
}

export interface SortableTableState {
  sortings: any;
  search: Array<SearchData>;
}

const getDefaultSortings = (props: SortableTableProps) => {
  return props.columns.map((column) => {
    let sorting = "both";
    if (column.defaultSorting) {
      const defaultSorting = column.defaultSorting.toLowerCase();

      if (defaultSorting == "desc") {
        sorting = "desc";
      } else if (defaultSorting == "asc") {
        sorting = "asc";
      }
    }
    return sorting;
  });
};

const getDefaultSearch = (props: SortableTableProps) => {
  return props.columns
    .map((c): string | undefined => c.key)
    .filter((k) => k !== undefined)
    .map(
      (k): SearchData => ({
        field: k as string,
        value: "",
      })
    );
};

const searchData = (data: Array<any>, search: Array<SearchData>) => {
  const relevant = search.filter((s) => s.value !== null && s.value !== "");
  return data.filter((d) => {
    for (const s of relevant) {
      const v: string | undefined = d[s.field];
      if (v === null || v === undefined || v === "") {
        return false;
      }
      if (!v.toString().toLowerCase().includes(s.value.toLowerCase())) {
        return false;
      }
    }
    return true;
  });
};

const sortData = (data: Array<any>, sortings: any, columns: Array<Column>) => {
  let sortedData = data;
  for (var i in sortings) {
    const sorting = sortings[i];
    const column = columns[i as any];
    const key = columns[i as any].key;
    switch (sorting) {
      case "desc":
        if (
          column.descSortFunction &&
          typeof column.descSortFunction == "function"
        ) {
          sortedData = column.descSortFunction(sortedData, key);
        } else {
          sortedData = descSortData(sortedData, key as string);
        }
        break;
      case "asc":
        if (
          column.ascSortFunction &&
          typeof column.ascSortFunction == "function"
        ) {
          sortedData = column.ascSortFunction(sortedData, key);
        } else {
          sortedData = ascSortData(sortedData, key as string);
        }
        break;
    }
  }
  return sortedData;
};

const ascSortData = (data: Array<any>, key: string) => {
  return sortDataByKey(data, key, (a: any, b: any) => {
    if (parseFloatable(a) && parseFloatable(b)) {
      a = parseIfFloat(a);
      b = parseIfFloat(b);
    }
    if (a >= b) {
      return 1;
    } else if (a < b) {
      return -1;
    }
  });
};

const descSortData = (data: Array<any>, key: string) => {
  return sortDataByKey(data, key, (a: any, b: any) => {
    if (parseFloatable(a) && parseFloatable(b)) {
      a = parseIfFloat(a);
      b = parseIfFloat(b);
    }
    if (a <= b) {
      return 1;
    } else if (a > b) {
      return -1;
    }
  });
};

const parseFloatable = (value: any) => {
  return typeof value === "string" &&
    (/^-?\d+$/.test(value) || /^-?\d+$/.test(value.replace(/[,.%$]/g, "")))
    ? true
    : false;
};

const parseIfFloat = (value: any) => {
  return parseFloat(value.replace(/,/g, ""));
};

const sortDataByKey = (data: any, key: any, fn: any) => {
  const clone = Array.apply(null, data);

  return clone.sort((a: any, b: any) => {
    return fn(a[key], b[key]);
  });
};

const nextSortingState = (state: any) => {
  let next;
  switch (state) {
    case "both":
      next = "desc";
      break;
    case "desc":
      next = "asc";
      break;
    case "asc":
      next = "both";
      break;
  }
  return next;
};

export const SortableTable = (props: SortableTableProps) => {
  const { columns } = props;
  const [data, setData] = useState(props.data as any);

  const [sortings, setSortings] = useState<any>(getDefaultSortings(props));
  const [search, setsearch] = useState<Array<SearchData>>(
    getDefaultSearch(props)
  );
  const [filter, setFilter] = useState<Array<SearchData>>(
    getDefaultSearch(props)
  );
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    setData(props?.data);
    setCheckAll(false);
  }, [props?.data]);
  const handleSortChange = (index: number) => {
    const newSortings = sortings.map((sorting: any, i: any) => {
      if (i == index) sorting = nextSortingState(sorting);

      return sorting;
    });

    setSortings(newSortings);
  };

  const handleSearch = (searchColumn: SearchData) => {
    setsearch(
      search.map((s) => (s.field === searchColumn.field ? searchColumn : s))
    );
    setCheckAll(false);
  };

  const handleFilter = (searchColumn: SearchData) => {
    setFilter(
      search.map((s) => (s.field === searchColumn.field ? searchColumn : s))
    );
    setCheckAll(false);
  };

  // handle check all checkbox and update data
  const handleCheckAll = (
    event: ChangeEvent<HTMLInputElement>,
    props: any,
    column: Column
  ) => {
    const filteredFields = props.search.filter((s: any) => s.value.length > 0);
    const searchedData = searchData(data as any, filteredFields);

    if (searchedData.length > 0 && filteredFields.length > 0) {
      data?.map((item: any) => {
        const isExisted = searchedData.some((searchedItem: any) => {
          return searchedItem.id === item.id;
        });
        if (isExisted) {
          item.selected = event.target.checked;
        } else {
          item.selected = false;
        }
      });
    } else if (filteredFields.length === 0) {
      data?.map((item: any) => {
        item.selected = event.target.checked;
      });
    }

    setData([...data]);

    // callback child prop function
    if (column.handleCallbackData) {
      setCheckAll(event.target.checked);
      column.handleCallbackData(event, data);
    }
  };

  // handle unchecked item on row
  const handleUnChecked = (
    event: ChangeEvent<HTMLInputElement>,
    props: any
  ) => {
    data?.map((currency: any) => {
      if (props.data.id === currency.id) {
        currency.selected = event.target.checked;
      }
      return currency;
    });
    props.column.handleCallbackData(event, data);

    const unCheckedAllItem = data?.filter(
      (item: any) => item.selected === false
    );

    if (unCheckedAllItem && unCheckedAllItem?.length > 0) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
    setData([...data]);
  };

  if (data === null || data === undefined) {
    return <Loading />;
  }

  const sortedData = sortData(
    searchData(data, [...search, ...filter]),
    sortings,
    props.columns
  );

  return (
    <div className="table-responsive">
      <table
        className="table text-dark table-bordered dataTable"
        style={props.style}
      >
        <SortableTableHeader
          sortedData={sortedData}
          columns={columns}
          sortings={sortings}
          search={search}
          onSortChange={handleSortChange}
          onSearch={handleSearch}
          onFilter={handleFilter}
          onCheckAll={handleCheckAll}
          isCheckAll={checkAll}
          iconStyle={props.iconStyle}
          iconDesc={props.iconDesc}
          iconAsc={props.iconAsc}
          iconBoth={props.iconBoth}
          showSearchBar={data.length > 3}
        />
        {data.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={columns.length}>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: 5,
                    borderBottom: " 1px solid #e3e6f0",
                  }}
                >
                  ---&nbsp;&nbsp;&nbsp;None&nbsp;&nbsp;&nbsp;---
                </p>
              </td>
            </tr>
          </tbody>
        ) : (
          <SortableTableBody
            key="SortableTableBody"
            columns={props.columns}
            data={sortedData}
            sortings={sortings}
            handleUnChecked={handleUnChecked}
          />
        )}
      </table>
      {props.bottomPagination && <SortableTableFooter {...props.bottomPagination}/> }
    </div>
  );
};
