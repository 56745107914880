import React, { ChangeEvent, Component, CSSProperties } from "react";
import { Column } from "../../../domain";
import { SortIconAsc, SortIconBoth, SortIconDesc } from "./icons";
import { SearchData } from "./types";
import { SortableTableHeaderFilter } from "./sortable-table-header-filter";
import { SortableTableHeaderCheckAll } from "./sortable-table-header-check-all";

interface SortableTableHeaderItemProps {
  headerProps?: any;
  sortable?: boolean;
  sorting: "desc" | "asc" | "both";
  iconStyle?: CSSProperties;
  iconDesc?: string;
  iconAsc?: JSX.Element;
  iconBoth?: JSX.Element;
  onClick?: any;
  style?: CSSProperties;
  header?: any;
}

const SortableTableHeaderItem = (props: SortableTableHeaderItemProps) => {
  const { onClick } = props;
  const sortable = props.sortable !== false;
  const headerProps = props.headerProps || {};

  let sortIcon;
  if (sortable) {
    if (props.iconBoth) {
      sortIcon = props.iconBoth;
    } else {
      sortIcon = <SortIconBoth style={props.iconStyle} />;
    }
    if (props.sorting == "desc") {
      if (props.iconDesc) {
        sortIcon = props.iconDesc;
      } else {
        sortIcon = <SortIconDesc style={props.iconStyle} />;
      }
    } else if (props.sorting == "asc") {
      if (props.iconAsc) {
        sortIcon = props.iconAsc;
      } else {
        sortIcon = <SortIconAsc style={props.iconStyle} />;
      }
    }
  }

  return (
    <th
      style={{ ...props.style, padding: "12px", minWidth: "200px" }}
      onClick={onClick}
      {...headerProps}
    >
      {props.header}
      {sortIcon}
    </th>
  );
};

interface SortableTableHeaderSearchProps {
  search: SearchData;
  headerProps: any;
  style: any;
  onChange: (search: SearchData) => void;
}
class SortableTableHeaderSearch extends Component<SortableTableHeaderSearchProps> {
  static defaultProps = {
    headerProps: {},
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({
      field: this.props.search.field,
      value: event.target.value,
    });
  };

  render() {
    const { search } = this.props;
    return (
      <td style={this.props.style} {...this.props.headerProps}>
        <input
          style={{ width: "100%" }}
          type="text"
          value={search.value}
          onChange={this.onChange}
        />
      </td>
    );
  }
}

interface SortableTableHeaderProps {
  sortedData: any[];
  columns: Array<Column>;
  sortings: any;
  search: Array<SearchData>;
  onSortChange?: (index: number) => void;
  isCheckAll: boolean;
  iconStyle?: any;
  iconDesc?: any;
  iconAsc?: any;
  iconBoth?: any;
  onSearch: (search: SearchData) => void;
  onFilter?: (search: SearchData) => void;
  onCheckAll?: (
    event: ChangeEvent<HTMLInputElement>,
    props: any,
    column: Column
  ) => void;
  showSearchBar: boolean;
}

export const SortableTableHeader = (props: SortableTableHeaderProps) => {
  const { onSortChange } = props;

  const headers = props.columns.map((column: Column, index: any) => {
    const sorting = props.sortings[index];
    return (
      <SortableTableHeaderItem
        sortable={column.sortable}
        key={index}
        header={column.header}
        sorting={sorting}
        onClick={
          onSortChange !== undefined ? () => onSortChange(index) : undefined
        }
        style={column.headerStyle}
        headerProps={column.headerProps}
        iconStyle={props.iconStyle}
        iconDesc={props.iconDesc}
        iconAsc={props.iconAsc}
        iconBoth={props.iconBoth}
      />
    );
  });

  const searchBar = props.columns.map((column: Column, index: number) => {
    const search = props.search.find((s) => s.field === column.key);
    const handleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
      if (column.handleCallbackData && props.onCheckAll) {
        props.onCheckAll(event, props, column);
      }
    };
    if (column.checkable) {
      return (
        <SortableTableHeaderCheckAll
          style={column.headerStyle}
          headerProps={column.headerProps}
          onChange={handleCheckAll}
          key={index}
          isCheckAll={props.isCheckAll}
        />
      );
    }

    if (column.filterable) {
      return (
        <SortableTableHeaderFilter
          search={search || ({} as SearchData)}
          key={index}
          onChange={props.onSearch}
          style={column.headerStyle}
          headerProps={column.headerProps}
          options={column.options || []}
        />
      );
    }

    if (
      column.key === undefined ||
      column.searchable === false ||
      search === undefined
    ) {
      return <td key={index}></td>;
    }

    return (
      <SortableTableHeaderSearch
        search={search}
        key={index}
        onChange={props.onSearch}
        style={column.headerStyle}
        headerProps={column.headerProps}
      />
    );
  });

  return (
    <thead>
      <tr>{headers}</tr>
      {props.showSearchBar ? <tr>{searchBar}</tr> : null}
    </thead>
  );
};
