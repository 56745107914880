import { applyMiddleware, createStore, Reducer, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

import { authReducer, AuthState } from "./auth";

const rootReducer = combineReducers({
  auth: authReducer,
});

export interface AppState {
  auth: AuthState;
}

export const store = createStore(
  rootReducer as Reducer<AppState>,
  applyMiddleware(thunkMiddleware)
);
