import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import { FormPopup } from "../../components/form-popup";
import {
  ADMIN_PERMISSION_ACTION,
  ADMIN_PERMISSION_CATEGORY,
} from "../../const/status";
import { AdminAuth, AdminGroup, SelectOption } from "../../domain";
import { api } from "../../util/api";
import { UpdateUserRequest } from "./types";
import { Loading } from "../../components/loading";

interface UserFormProps {
  title: string;
  id: string;
  admin_group_id: string;
  onCancel: () => void;
  onSave: () => void;
}
export const UserForm = (props: UserFormProps) => {
  const { onCancel, onSave } = props;
  const [groupOptions, setGroupOptions] = useState<Array<{
    label: string;
    value: string;
  }> | null>(null);
  const [group, setGroup] = useState<SelectOption | null>(null);

  const handleFormClick = async () => {
    if (props.id && group !== null) {
      api
        .putUpdateUser(props.id, {
          admin_group_id: group.value,
        } as UpdateUserRequest)
        .then((result) => {
          onSave();
          onCancel();
        });
    }
  };

  useEffect(() => {
    api.getGroups().then((g: AdminGroup[]) => {
      let defaultGroup = 0;
      const option = g.map((v, index) => {
        if (props.admin_group_id === v.id) {
          defaultGroup = index;
        }
        return { label: v.label, value: v.id };
      });
      setGroupOptions(option);
      if (option.length > 0) {
        setGroup(option[defaultGroup]);
      }
    });
  }, []);
  return (
    <FormPopup
      title={props.title}
      onSubmit={handleFormClick}
      onCancel={onCancel}
    >
      <div className="row">
        <div className="col-md-12">
          <label>Group</label>
          {groupOptions === null ? (
            <Loading />
          ) : (
            <Select
              className="form-control"
              values={group !== null ? [group] : []}
              name={"group"}
              onChange={(val: any[]) => {
                setGroup(val[0]);
              }}
              options={groupOptions}
            />
          )}
        </div>
      </div>
    </FormPopup>
  );
};
