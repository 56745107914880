import { Column, Transaction } from "../domain";
import { formatDate } from "../util/labels";

export const REPUTATION_COLUMNS: Array<Column> = [
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Correlation Key",
    key: "correlation_key",
  },
  {
    header: "Latest Session",
    key: "latest_session",
    render: formatDate,
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Name",
    key: "full_legal_name",
  },
  {
    header: "Singpass ID",
    key: "singpass_id",
  },
];

export const CreatedAtSorter = {
  desc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at < _b.created_at) {
        return 1;
      }
      if (_a.created_at > _b.created_at) {
        return -1;
      }
      return 0;
    });
  },

  asc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at > _b.created_at) {
        return 1;
      }
      if (_a.created_at < _b.created_at) {
        return -1;
      }
      return 0;
    });
  },
};

export const ISSUER_COLUMNS: Array<Column> = [
  {
    header: "Bin",
    key: "bin",
  },
  {
    header: "Issuer",
    key: "issuer",
  },
  {
    header: "Country",
    key: "issuer_country",
  },
  {
    header: "Card Type",
    key: "card_type",
  },
  {
    header: "Scheme",
    key: "scheme",
  },
  {
    header: "Attempted",
    key: "attempted",
  },
  {
    header: "Filled",
    key: "filled",
  },
];

export const TRANSACTION_REPORTING_LIST_COLUMNS: Array<Column> = [
  {
    header: "Liquid UserId",
    key: "liquid_user_id",
    searchable: false,
  },
  {
    header: "Status",
    key: "status",
    searchable: false,
  },
  {
    header: "Outcome",
    key: "outcome_category",
    searchable: false,
  },
  {
    header: "Last Log",
    key: "last_log",
    searchable: false,
  },
  {
    header: "Sell",
    func: (row: any) => `${row.sell_ccy} ${row.sell_qty}`,
    sortable: false,
  },
  {
    header: "",
    func: () => "->",
    sortable: false,
  },
  {
    header: "Buy",
    func: (row: any) => `${row.buy_ccy} ${row.buy_qty}`,
    sortable: false,
    searchable: false,
  },
  {
    header: "Usd Equivalent",
    key: "usd_equivalent",
    searchable: false,
  },
];
