import React from "react";

export enum FIELD_TYPE {
  TEXT = "TEXT",
  SELECT = "SELECT",
  CHECKBOX = "CHECKBOX",
}

export interface ComposeFormField {
  label: string;
  id: string;
  type?: FIELD_TYPE;
  options?: Array<ComposeSelectOption>;
}

export interface ComposeFormProps<T = any> {
  fields: Array<ComposeFormField>;
  value: T;
  onChange: (val: T) => void;
  onSubmit: () => void;
}

export const ComposeForm = (props: ComposeFormProps) => {
  return (
    <form>
      {props.fields.map((f) => (
        <ComposeField
          key={f.id}
          {...f}
          value={props.value[f.id]}
          onChange={(val) => props.onChange({ ...props.value, [f.id]: val })}
        />
      ))}
      <div className="form-group row">
        <div className="col-sm-12" style={{ textAlign: "right" }}>
          {" "}
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={props.onSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

interface ComposeSelectOption {
  label: string;
  value: string;
}

interface ComposeFieldProps extends ComposeFormField {
  value: string;
  onChange: (v: string) => void;
  options?: Array<ComposeSelectOption>;
}

const ComposeField = (props: ComposeFieldProps) => {
  switch (props.type) {
    case FIELD_TYPE.SELECT:
      return ComposeFieldSelect(props);
    case FIELD_TYPE.CHECKBOX:
      return ComposeFieldCheckbox(props);
    case FIELD_TYPE.TEXT:
    default:
      return ComposeFieldText(props);
  }
};

const ComposeFieldSelect = (props: ComposeFieldProps) => (
  <div className="form-group row" key={props.id}>
    <label className="col-sm-3 col-form-label">{props.label}</label>
    <div className="col-sm-9">
      <select
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {props.options !== undefined
          ? props.options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))
          : undefined}
      </select>
    </div>
  </div>
);

const ComposeFieldText = (props: ComposeFieldProps) => (
  <div className="form-group row" key={props.id}>
    <label className="col-sm-3 col-form-label">{props.label}</label>
    <div className="col-sm-9">
      <input
        id={props.id}
        type="text"
        className="form-control"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  </div>
);

const ComposeFieldCheckbox = (props: ComposeFieldProps) => (
  <div className="form-group row" key={props.id}>
    <label className="col-sm-3 col-form-label">{props.label}</label>
    <div className="col-sm-9">
      <input
        id={props.id}
        type="checkbox"
        className="form-control"
        checked={props.value === "true"}
        onChange={(e) => props.onChange(e.target.checked ? "true" : "false")}
      />
    </div>
  </div>
);
