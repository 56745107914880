import { stat } from "fs";
import React, { Dispatch, useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import { Loading } from "../../components";
import { AppState } from "../../store";
import { attemptTokenSignin } from "../../store/auth";
import Logo from "./logo512.png";
import "./style.scss";

const clientId =
  "659778147380-ab3auhv8rjjjd9aof34n6bgam5oo8m4k.apps.googleusercontent.com";

export interface LoginViewProps {
  attemptTokenSignin: (id_token: string) => void;
}

export const LoginView = (props: LoginViewProps) => {
  const { attemptTokenSignin } = props;
  const [showLogin, setShowLogin] = useState<any | null>();
  const handleGoogleSuccess = (googleUser: any) => {
    var id_token = googleUser.getAuthResponse().id_token;
    setShowLogin(false);

    attemptTokenSignin(id_token);
  };
  const handleGoogleFailed = (error: any) => {
    console.error(error);
  };
  const { signIn, loaded } = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    isSignedIn: true,
    clientId,
  });

  useEffect(() => {
    signIn();
  }, []);

  return (
    <div className="login-view">
      <div className="center-float">
        <div>
          <img src={Logo} />
          <h1>QuickExchange</h1>
        </div>
        <div className="button-wrapper">
          {loaded && !showLogin ? (
            <GoogleLogin
              clientId={clientId}
              buttonText="Login"
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailed}
              cookiePolicy={"single_host_origin"}
            />
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    attemptTokenSignin: (id_token: string) =>
      dispatch(attemptTokenSignin(id_token)),
  };
};

export const LoginViewContainer = connect<any, any, any, AppState>(
  null,
  mapDispatchToProps
)(LoginView);
