import React, { useEffect, useState } from "react";
import { Column, ReputationGraph } from "../../domain";
import "./style.css";
import { api } from "../../util/api";
import { Loading } from "../../components/loading";
import { LinkableTable } from "../../components/linkable-table";
import { EChart } from "../../components/e-chart";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { JsonBlob } from "../../components";

const documentsColumns: Array<Column> = [
  {
    header: "element",
    key: "element",
  },
  {
    header: "mechanism",
    key: "mechanism",
  },
  {
    header: "Form",
    key: "document_form",
  },
  {
    header: "Country",
    key: "document_country",
  },
  {
    header: "Status",
    key: "status",
  },
];
export interface ReputationViewProps {
  id: string;
}

export interface ReputationViewState {
  graph: ReputationGraph | null;
  transactions: Array<any> | null;
  proofs: Array<any> | null;
  logs: any;
}

const logsColumns: Array<Column> = [
  {
    header: "Primary event",
    key: "primary_event",
  },
  {
    header: "Context event",
    key: "context_event",
  },
];

const kycIdentityColumns: Array<Column> = [
  {
    header: "Status",
    key: "status",
  },
];

export const ReputationView = (props: ReputationViewProps) => {
  const { id } = useParams<{ id: string }>();
  const [reputation, setReputation] = useState<any | null>(null);

  const [graph, setGraph] = useState<ReputationGraph | null>(null);
  const [logs, setLogs] = useState<any | null>(null);
  const [state, setState] = useState<any | null>(null);
  const [documents, setDocuments] = useState<any | null>(null);
  const [sessions, setSessions] = useState<any | null>(null);

  useEffect(() => {
    api.getReputation(id).then(setReputation);
    api.getKycState(id).then(setState);
    api.getKycDocuments(id).then(setDocuments);

    api.getReputationLogs(id).then(setLogs);
    api.getReputationGraph(id).then(setGraph);
    api.getReputationSessions(id).then(setSessions);
  }, [id]);

  if (graph === null) {
    return <Loading />;
  }

  // the graph configuration, you only need to pass down properties
  // that you want to override, otherwise default ones will be used
  const myConfig = {
    staticGraph: false,
    staticGraphWithDragAndDrop: false,
    panAndZoom: false,
    node: {
      color: "lightgreen",
      size: 120,
      fontSize: 10,
      highlightStrokeColor: "blue",
      labelProperty: (n: any) => n.label,
    },
    link: {
      highlightColor: "lightblue",
    },
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Card label="Reputation">
            <JsonBlob blob={reputation} />
          </Card>
        </div>
        <div className="col-lg-12">
          <Card label="Sessions">
            <LinkableTable
              idUrl={`/session/:id`}
              data={sessions}
              columns={[]}
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Reputation Keys">
            <LinkableTable
              idUrl={`/reputation-key/:id`}
              data={graph.keys}
              columns={[
                {
                  key: "type",
                  header: "Type",
                },
                {
                  key: "value",
                  header: "Value",
                },
              ]}
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Kyc State">
            <JsonBlob blob={state} />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <Card label="Kyc Documents">
            <LinkableTable
              data={documents}
              columns={documentsColumns}
              idUrl="/kyc/document/:id"
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <Card label={"Logs"}>
            <LinkableTable
              idUrl="/reputation/log/:id"
              data={logs}
              columns={logsColumns}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
