import React from "react";
import { ID_URLS } from "../../const";
import { Blobable, Column } from "../../domain";
import { Transaction } from "../../domain/transaction";
import { formatDate, uuidLabel } from "../../util/labels";
import { IdLink } from "../id-link";
import { SortableTable } from "./sortable-table";
import { SortableTableFooterProps } from "./sortable-table/sortable-table-footer";

export interface TableProps {
  idField?: string;
  data: Array<Blobable> | null;
  columns?: Array<Column>;
  idUrl?: string;
  createdAtCol?: boolean;
  idTruncate?: number;
  idSearchable?: boolean;
  bottomPagination?: SortableTableFooterProps
}

const CreatedAtSorter = {
  desc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at < _b.created_at) {
        return 1;
      }
      if (_a.created_at > _b.created_at) {
        return -1;
      }
      return 0;
    });
  },

  asc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at > _b.created_at) {
        return 1;
      }
      if (_a.created_at < _b.created_at) {
        return -1;
      }
      return 0;
    });
  },
};

const defaultColumns: Array<Column> = [];

const getColumns = (
  columns: Array<Column>,
  createdAtCol: boolean,
  idField: string,
  idUrl?: string,
  idTruncate?: number,
  idSearchable?: boolean
): Array<Column> => {
  return [
    {
      header: "ID",
      key: idField,
      headerStyle: { backgroundColor: "#FFDAB9" },
      dataStyle: { backgroundColor: "#FFDAB9" },
      render: (id: string) =>
        idUrl !== undefined ? (
          <IdLink id={id} url={idUrl} truncate={idTruncate || 8} />
        ) : (
          uuidLabel(id)
        ),
      sortable: false,
      searchable: idSearchable,
    },
    ...(createdAtCol
      ? ([
          {
            header: "Created At",
            key: "created_at",
            render: formatDate,
            defaultSorting: "DESC",
            searchable: false,
            descSortFunction: CreatedAtSorter.desc,
            ascSortFunction: CreatedAtSorter.asc,
          },
        ] as any)
      : []),
    ...columns.map((c) => {
      if (c.key === undefined || c.render !== undefined) {
        return c;
      }
      const link = ID_URLS[c.key];
      if (link === undefined) {
        return c;
      }
      return {
        ...c,
        render: (val: string) => <IdLink id={val} url={link} truncate={8} />,
      };
    }),
  ];
};

export const LinkableTable = (props: TableProps) => {
  const {
    data,
    columns,
    idUrl,
    idField,
    createdAtCol,
    idTruncate,
    idSearchable,
    bottomPagination
  } = props;

  const style = {
    fontSize: 11,
  };

  const iconStyle = {
    color: "#aaa",
    paddingLeft: "5px",
    paddingRight: "5px",
  };

  return (
    <SortableTable
      data={data}
      columns={getColumns(
        columns || defaultColumns,
        createdAtCol !== false,
        idField || "id",
        idUrl,
        idTruncate,
        idSearchable
      )}
      style={style}
      iconStyle={iconStyle}
      bottomPagination={bottomPagination}
    />
  );
};
