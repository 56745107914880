export enum TRANSACTION_STATUS {
  INPUT_REQUIRED = "INPUT_REQUIRED",
  VALIDATING = "VALIDATING",
  PROCESSING = "PROCESSING",
  CANCELLING = "CANCELLING",
  FILLED = "FILLED",
  VOID = "VOID",
  ABANDONED = "ABANDONED",
  INTERVENTION_REQUIRED = "INTERVENTION_REQUIRED",
}

export enum SETTLEMENT_INSTRUCTION_STATUS {
  QUEUED = "QUEUED",
  STARTED = "STARTED",
  PARAMETERS_REQUIRED = "PARAMETERS_REQUIRED",
  READY = "READY",
  WAITING_USER = "WAITING_USER",
  WAITING_EXTERNAL = "WAITING_EXTERNAL",
  WAITING_INTERNAL = "WAITING_INTERNAL",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  MANUAL_INTERVENTION_REQUIRED = "MANUAL_INTERVENTION_REQUIRED",
}

export enum PARTNER_ENVIRONMENT {
  PRODUCTION = "PRODUCTION",
  SANDBOX = "SANDBOX",
}

export enum TRANSACTION_OUTCOME_CATEGORY {
  FILLED = "FILLED",
  ABANDONED = "ABANDONED",
  EXTERNAL_REJECT = "EXTERNAL_REJECT",
  INTERNAL_REJECT = "INTERNAL_REJECT",
  EXTERNAL_FAULT = "EXTERNAL_FAULT",
  INTERNAL_FAULT = "INTERNAL_FAULT",
}

export enum TRANSACTION_OUTCOME_REASON {
  BLOCKCHAIN_UNEXPECTED_BROADCAST_RESPONSE = "BLOCKCHAIN_UNEXPECTED_BROADCAST_RESPONSE",
  BLOCKCHAIN_DEPOSIT_TIMEOUT = "BLOCKCHAIN_DEPOSIT_TIMEOUT",
  CARD_VELOCITY_THRESHOLD = "CARD_VELOCITY_THRESHOLD",
  CARD_ISSUER_DECLINED_TRANSACTION = "CARD_ISSUER_DECLINED_TRANSACTION",
  CARD_ISSUER_NOT_ALLOWED = "CARD_ISSUER_NOT_ALLOWED",
  CARD_ISSUER_COUNTRY_NOT_ALLOWED = "CARD_ISSUER_COUNTRY_NOT_ALLOWED",
  CARD_INSUFFICIENT_FUNDS = "CARD_INSUFFICIENT_FUNDS",
  CARD_3DS_FAILED = "CARD_3DS_FAILED",
  CARD_CVV_FAILED = "CARD_CVV_FAILED",
  CARD_EXPIRED = "CARD_EXPIRED",
  CARD_BILLING_ADDRESS_INVALID = "CARD_BILLING_ADDRESS_INVALID",
  PAYOUT_COMPLETED = "PAYOUT_COMPLETED",
  UNSPECIFIED_ERROR = "UNSPECIFIED_ERROR",
  USER_INPUT_TIMEOUT = "USER_INPUT_TIMEOUT",
}

export enum CURRENCY_METHOD_INHERIT_STYLE {
  OVERRIDE = "OVERRIDE",
  MERGE = "MERGE",
}

export enum CURRENCY_TYPE {
  FIAT = "FIAT",
  CRYPTO = "CRYPTO",
}

export enum TRUE_FALSE_TYPE {
  TRUE = "TRUE",
  FALSE = "FALSE",
}

export enum SETTLEMENT_METHOD {
  LIQUID_WALLET = "LIQUID_WALLET",
  LIQUID_USER_WALLET = "LIQUID_USER_WALLET",
  LIQUID_PARTNER_WALLET = "LIQUID_PARTNER_WALLET",
  BLOCKCHAIN_TRANSFER = "BLOCKCHAIN_TRANSFER",
  BLOCKCHAIN_DELEGATED_BROADCAST = "BLOCKCHAIN_DELEGATED_BROADCAST",
  CARD_PAYMENT = "CARD_PAYMENT",
  FINASTRA_TRANSFER = "FINASTRA_TRANSFER",
}

export enum DEPENDENCY {
  BACKEND_RESOURCE = "BACKEND_RESOURCE",
  BITCOIN_COM_RESOURCE = "BITCOIN_COM_RESOURCE",
  BLOCKCHAIN_RESOURCE = "BLOCKCHAIN_RESOURCE",
  CKO_RESOURCE = "CKO_RESOURCE",
  FINASTRA_RESOURCE = "FINASTRA_RESOURCE",
  LIQUID_RESOURCE = "LIQUID_RESOURCE",
  QUICK_EXCHANGE_RESOURCE = "QUICK_EXCHANGE_RESOURCE",
}

export enum DEPENDENCY_ALLOCATION {
  PRODUCTION = "PRODUCTION",
  MOCK = "MOCK",
}

export enum ADMIN_PERMISSION_CATEGORY {
  USERS = "USERS",
  TRANSACTIONS = "TRANSACTIONS",
  SETTLEMENTS = "SETTLEMENTS",
  KYC = "KYC",
  REPORTING = "REPORTING",
}

export enum ADMIN_PERMISSION_ACTION {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}
