export enum REPUTATION_KEY_TYPE {
  IPV4 = "IPV4",
  EMAIL = "EMAIL",
  CORRELATION_KEY = "CORRELATION_KEY",
  LIQUID_USER_ID = "LIQUID_USER_ID",
  SINGPASS_ID = "SINGPASS_ID",
  LIQUID_API_TOKEN_ID = "LIQUID_API_TOKEN_ID",
  LIQUID_VENDOR_ID = "LIQUID_VENDOR_ID",
  PHONE = "PHONE",
  DOMICILE_COUNTRY = "DOMICILE_COUNTRY",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  FULL_LEGAL_NAME = "FULL_LEGAL_NAME",
  HANDOFF_ID = "HANDOFF_ID",
  FTX_TENANT = "FTX_TENANT",
}
