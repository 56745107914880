import React from "react";

import { PartnerDependencyView } from "./containers/partner-dependency-view";

import { UsersView } from "./containers/users-view";
import { UserDetailView } from "./containers/user-detail-view";
import { GroupsView } from "./containers/groups-view";
import { GroupDetailView } from "./containers/group-detail-view";
import { PermissionView } from "./containers/permission-view";
import { PartnerConfigView } from "./containers/partner-config-view";
import { DashboardView } from "./containers/dashboard-view";
import { ReputationSearchView } from "./containers/reputation-search-view";
import { PartnerView } from "./containers/partner-view";

import { TransactionLogView } from "./containers/event-log-view";
import { TestSessionView } from "./containers/test-session-view";
import { SessionView } from "./containers/session-view";
import { ReputationView } from "./containers/reputation-view";
import { ReputationKeyView } from "./containers/reputation-key-view";
import { KycDocumentView } from "./containers/kyc-document-view";
import { TestSingpassView } from "./containers/test-singpass-view";
import { TestFtxView } from "./containers/test-ftx-view";
import { SystemLogView } from "./containers/system-log-view";
import { SystemLogDetailView } from "./containers/system-log-view-detail";

export const routes = {
  "/": () => <DashboardView />,
  "/reputation": () => <ReputationSearchView />,
  "/partner/:id": ({ id }: any) => <PartnerView apiKey={id} />,
  "/partner_config/:id": () => <PartnerConfigView />,

  "/transaction/log/:id": ({ id }: any) => (
    <TransactionLogView id={id} type="transaction-log" />
  ),
  "/reputation/log/:id": ({ id }: any) => (
    <TransactionLogView id={id} type="reputation-log" />
  ),
  "/frontend-log/:id": ({ id }: any) => (
    <TransactionLogView id={id} type="frontend-log" />
  ),
  "/test/session": () => <TestSessionView />,
  "/test/singpass": () => <TestSingpassView />,
  "/test/ftx": () => <TestFtxView />,

  "/session/:id": ({ id }: any) => <SessionView id={id} />,
  "/reputation/:id": ({ id }: any) => <ReputationView id={id} />,
  "/reputation-key/:id": ({ id }: any) => <ReputationKeyView id={id} />,

  "/kyc/log/:id": ({ id }: any) => (
    <TransactionLogView id={id} type="kyc-log" />
  ),
  "/kyc/document/:id": ({ id }: any) => <KycDocumentView id={id} />,
  "/partner/:partner_id/dependency/:id": () => <PartnerDependencyView />,

  "/admin/users": () => <UsersView />,
  "/admin/user/:id": () => <UserDetailView />,
  "/admin/groups": () => <GroupsView />,
  "/admin/group/:id": () => <GroupDetailView />,
  "/admin/group/:admin_group_id/permission/:id": () => <PermissionView />,
  "/report/system_logs": () => <SystemLogView />,
  "/report/system_logs/:id": () => <SystemLogDetailView />,
};
