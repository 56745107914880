import React, { ChangeEvent, Component } from "react";
import { Column } from "../../../domain";
import { SortableRowInputs } from "./sortable-row-inputs";

export const getValue = (colDef: any, rowData: any) => {
  if (colDef.key !== undefined) {
    return rowData[colDef.key];
  }
  if (colDef.func !== undefined) {
    return colDef.func(rowData);
  }
  return "??";
};
export interface SortableTableRowProps {
  columns: Array<Column>;
  data: Array<any>;
  handleUnChecked: (event: ChangeEvent<HTMLInputElement>, props: any) => void;
}
const SortableTableRow = (props: SortableTableRowProps) => {
  var tds = props.columns.map((item: Column, index: number) => {
    var value = getValue(item, props.data);
    if (item.render) {
      value = item.render(value);
    }
    if (typeof value === "boolean") {
      value = value === true ? "True" : "False";
    }
    let jsxElement: any;
    switch (item.type) {
      case "CHECKBOX":
        jsxElement = (
          <SortableRowInputs
            column={item}
            value={value}
            data={props.data}
            handleUnChecked={props.handleUnChecked}
          />
        );
        break;
      default:
        jsxElement = value;
        break;
    }
    return (
      <td
        scope="row"
        key={index}
        style={item.dataStyle}
        {...(item.dataProps || {})}
      >
        {jsxElement}
      </td>
    );
  });

  return <tr>{tds}</tr>;
};
interface SortableTableBodyProps {
  data: Array<any>;
  columns: Array<Column>;
  sortings: Array<any>;
  handleUnChecked: (event: ChangeEvent<HTMLInputElement>, props: any) => void;
}

export const SortableTableBody = (props: SortableTableBodyProps) => {
  var bodies = props.data.map((item: any, index: any) => {
    return (
      <SortableTableRow
        key={index}
        data={item}
        columns={props.columns}
        handleUnChecked={props.handleUnChecked}
      />
    );
  });

  return <tbody>{bodies}</tbody>;
};
