import React, { useEffect, useState, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { LinkableTable } from "../../components/linkable-table";
import { JsonBlob } from "../../components/json-blob";
import { AdminGroup, AdminPermission, Column } from "../../domain";
import { api } from "../../util/api";
import { GroupForm } from "./group-form";
import { PermissionForm } from "../permission-view/permission-form";
import { Loading } from "../../components/loading";

export const GroupDetailView = () => {
  let { id } = useParams<{ id: string }>();
  const [permissionIdsSelected, setPermissionIdsSelected] = useState<string[]>(
    []
  );

  const [adminGroup, setAdminGroup] = useState<AdminGroup | null>(null);

  const [adminPermissions, setAdminPermissions] = useState<AdminPermission[]>(
    []
  );

  const [updateGroupForm, setUpdateGroupForm] = useState<boolean>(false);

  const [onSaveGroupForm, setOnSaveGroupForm] = useState<boolean>(false);

  const [createPermissionForm, setCreatePermissionForm] =
    useState<boolean>(false);

  const [onSavePermissionForm, setOnSavePermissionForm] =
    useState<boolean>(false);

  const handleCallbackData = (
    event: ChangeEvent<HTMLInputElement>,
    item: AdminPermission
  ): void => {
    const perIds: string[] = [];
    let permissionsSelected = [];
    if (event.target.name === "checkAll") {
      permissionsSelected = adminPermissions?.map((p) => {
        p.selected = event.target.checked;
        if (p.selected) {
          perIds.push(p.id);
        }
        return p;
      });
    } else {
      permissionsSelected = adminPermissions?.map((p) => {
        if (p.id === item.id) {
          p.selected = event.target.checked;
        }
        if (p.selected) {
          perIds.push(p.id);
        }
        return p;
      });
    }
    setAdminPermissions(permissionsSelected);
    setPermissionIdsSelected(perIds);
  };

  const deletePermissions = () => {
    api.putDeletePermission({ ids: permissionIdsSelected }).then(() => {
      setOnSavePermissionForm(true);
    });
  };

  const permissionColumns: Array<Column> = [
    {
      header: "Category",
      key: "category",
    },
    {
      header: "Action",
      key: "action",
    },
    {
      header: "Selects",
      key: "id",
      searchable: false,
      checkable: true,
      type: "CHECKBOX",
      handleCallbackData,
      additionalField: "id",
    },
  ];

  useEffect(() => {
    api.getGroupById(id).then((g: AdminGroup) => {
      setAdminGroup(g);
    });
    if (onSaveGroupForm) {
      setOnSaveGroupForm(false);
    }
  }, [id, onSaveGroupForm]);
  useEffect(() => {
    api.getAdminPermissions(id).then((adPs: AdminPermission[]) => {
      setAdminPermissions(adPs);
    });
    if (onSavePermissionForm) {
      setOnSavePermissionForm(false);
    }
  }, [id, onSavePermissionForm]);
  return (
    <div className="partner-summaries">
      {updateGroupForm && (
        <GroupForm
          title="Edit group"
          adminGroup={adminGroup}
          onCancel={() => setUpdateGroupForm(false)}
          onSave={() => setOnSaveGroupForm(true)}
        />
      )}
      {createPermissionForm && (
        <PermissionForm
          title="Add a new permission"
          id={id}
          admin_group_id={`${adminGroup?.id}`}
          adminPermission={null}
          onCancel={() => setCreatePermissionForm(false)}
          onSave={() => setOnSavePermissionForm(true)}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <Card label={`Admin Group: ${adminGroup?.label}`}>
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setUpdateGroupForm(true);
                  }}
                >
                  Edit Group
                </button>
              </div>
            </div>
            <JsonBlob blob={adminGroup} />
          </Card>
        </div>
        <div className="col-md-12">
          <Card label={`Permissions:`}>
            <div className="mb-4">
              <button
                className="btn btn-dark"
                onClick={() => {
                  setCreatePermissionForm(true);
                }}
              >
                Add permission
              </button>
              <button
                disabled={permissionIdsSelected.length === 0}
                className="btn btn-dark ml-2"
                onClick={() => {
                  deletePermissions();
                }}
              >
                Remove permissions
              </button>
            </div>
            {adminPermissions === null ? (
              <Loading />
            ) : (
              <LinkableTable
                idUrl={`/admin/group/${adminGroup?.id}/permission/:id`}
                idField="id"
                data={adminPermissions}
                columns={permissionColumns}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
