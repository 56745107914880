import React, { useEffect, useState } from "react";
import { Loading } from "../../components/loading";
import { LinkableTable } from "../../components/linkable-table";
import { PARTNER_ENVIRONMENT } from "../../const/status";
import { Column, PartnerStatistics } from "../../domain";
import { api } from "../../util/api";
import "./style.css";
import { Card } from "../../components/card";
import { PartnerForm } from "../partner-view/partner-form";
const partnerColumns: Array<Column> = [
  {
    header: "Name",
    key: "label",
  },
  {
    header: "Environment",
    key: "environment",
  },
  {
    header: "Total",
    key: "total_transactions",
  },
  {
    header: "Filled",
    key: "filled_transactions",
  },
];

export const DashboardView = () => {
  const [partners, setPartners] = useState<Array<PartnerStatistics> | null>(
    null
  );

  const [onOpenCreatePartnerForm, setOnOpenCreatePartnerForm] =
    useState<boolean>(false);
  const [onSavePartnerForm, setOnSavePartnerForm] = useState<boolean>(false);
  const [apiKeySearch, setApiKeySearch] = useState("");
  useEffect(() => {
    if (partners === null || (onSavePartnerForm && partners)) {
      api.getPartners().then((receivedPartners) => {
        console.log(receivedPartners);
        setPartners(receivedPartners);
        setOnSavePartnerForm(false);
        setOnOpenCreatePartnerForm(false);
        receivedPartners.forEach((p1) => {
          api.getPartnerStatistics(p1.id).then((s) => {
            setPartners((currentPartners) => {
              if (currentPartners !== null) {
                return currentPartners.map((p2) => {
                  if (p2.id !== p1.id) {
                    return p2;
                  }
                  return { ...p2, ...s };
                });
              }
              return null;
            });
          });
          api.getPartnerApiKeys(p1.id).then((api_keys) => {
            setPartners((currentPartners) => {
              if (currentPartners !== null) {
                return currentPartners.map((p2) => {
                  if (p2.id !== p1.id) {
                    return p2;
                  }
                  return { ...p2, api_keys };
                });
              }
              return null;
            });
          });
        });
      });
    }
  }, [onSavePartnerForm]);
  if (partners === null) {
    return <Loading />;
  }

  return (
    <div className="transaction-view">
      {onOpenCreatePartnerForm && (
        <PartnerForm
          title={"Creat a new partner"}
          partnerConfig={null}
          onCancel={() => setOnOpenCreatePartnerForm(false)}
          onSave={() => setOnSavePartnerForm(true)}
        />
      )}
      <div className="row">
        <div className="col-xl-12">
          <Card label={"Partners"}>
            <div className="row mb-4">
              <div className="col-md-6">
                <label className="mr-2">Api Key Search</label>
                <input
                  onChange={(e) => setApiKeySearch(e.target.value)}
                  value={apiKeySearch}
                />
              </div>
              <div className="col-md-6 text-right">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setOnOpenCreatePartnerForm(true);
                  }}
                >
                  Create partner
                </button>
              </div>
            </div>
            {partners === null ? (
              <Loading />
            ) : (
              <LinkableTable
                idUrl="/partner/:id"
                idField="id"
                idTruncate={8}
                data={partners.filter((p) => {
                  return (
                    apiKeySearch === "" ||
                    (p.api_keys !== undefined &&
                      p.api_keys.find((a) =>
                        a.public_api_key.includes(apiKeySearch)
                      ) !== undefined)
                  );
                })}
                columns={partnerColumns}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
