import { AdminLogin, GetState } from "../../domain";
import { AuthAction, AUTH_ACTIONS } from "./types";
import { api } from "../../util/api";
import { Dispatch } from "react";

export const requestLogin = (): AuthAction => {
  return {
    type: AUTH_ACTIONS.REQUEST_LOGIN,
  };
};

export const receiveLogin = (login: AdminLogin): AuthAction => {
  return {
    type: AUTH_ACTIONS.RECEIVE_LOGIN,
    payload: login,
  };
};

export const attemptTokenSignin = (id_token: string) => {
  return function (dispatch: Dispatch<AuthAction>, getState: GetState) {
    dispatch(requestLogin());
    api.postTokensignin(id_token).then((adminLogin: AdminLogin) => {
      api.setAuth(adminLogin.bearer);
      dispatch(receiveLogin(adminLogin));
    });
  };
};

export const logout = (): AuthAction => {
  return {
    type: AUTH_ACTIONS.LOGOUT,
  };
};
export const logoutAction = () => {
  return function (dispatch: Dispatch<AuthAction>, getState: GetState) {
    // TODO: delete cookie google 
    dispatch(logout());
  };
};
