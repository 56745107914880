import React, { useState } from "react";
import Select from "react-dropdown-select";
import { FormPopup } from "../../components/form-popup";
import {
  ADMIN_PERMISSION_ACTION,
  ADMIN_PERMISSION_CATEGORY,
} from "../../const/status";
import { AdminPermission, SelectOption } from "../../domain";
import { api } from "../../util/api";
import { CreatePermissionRequest } from "./types";

interface PermissionFormProps {
  title: string;
  id: string;
  admin_group_id: string;
  adminPermission: AdminPermission | null;
  onCancel: () => void;
  onSave: () => void;
}
export const PermissionForm = (props: PermissionFormProps) => {
  const { onCancel, onSave } = props;
  const [adminPermission, setAdminPermission] =
    useState<CreatePermissionRequest>({
      admin_group_id: props.admin_group_id,
      category: props.adminPermission?.category as ADMIN_PERMISSION_CATEGORY,
      action: props.adminPermission?.action as ADMIN_PERMISSION_ACTION,
    });
  const handleFormClick = async () => {
    if (props.adminPermission?.id) {
      api
        .putUpdatePermission(props.adminPermission.id, {
          ...adminPermission,
          action: action.value,
          category: category.value,
        } as CreatePermissionRequest)
        .then((result) => {
          onSave();
          onCancel();
        })
        .catch((e) => {
          if (e !== undefined) {
            setErrorMessage(e.message);
          }
        });
    } else {
      api
        .postCreatePermission({
          ...adminPermission,
          action: action.value,
          category: category.value,
        } as CreatePermissionRequest)
        .then((result) => {
          onSave();
          onCancel();
        })
        .catch((e) => {
          if (e !== undefined) {
            setErrorMessage(e.message);
          }
        });
    }
  };
  let defaultAction = 0;
  const ACTION_OPTIONS: Array<SelectOption> = Object.keys(
    ADMIN_PERMISSION_ACTION
  ).map((s, index) => {
    if (adminPermission && adminPermission.action === s) {
      defaultAction = index;
    }
    return { label: s, value: s };
  });
  let defaultCategory = 0;
  const CATEGORY_OPTIONS: Array<SelectOption> = Object.keys(
    ADMIN_PERMISSION_CATEGORY
  ).map((s, index) => {
    if (adminPermission && adminPermission.category === s) {
      defaultCategory = index;
    }
    return { label: s, value: s };
  });

  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [action, setAction] = useState<SelectOption>(
    ACTION_OPTIONS[defaultAction]
  );
  const [category, setCategory] = useState<SelectOption>(
    CATEGORY_OPTIONS[defaultCategory]
  );
  return (
    <FormPopup
      title={props.title}
      onSubmit={handleFormClick}
      onCancel={onCancel}
    >
      <div className="row">
        <div className="col-md-12">
          <label>Category</label>
          <Select
            className="form-control"
            values={[category]}
            name={"category"}
            onChange={(val: any[]) => {
              setCategory(val[0]);
            }}
            options={CATEGORY_OPTIONS}
          />
          <label>Action</label>
          <Select
            className="form-control"
            values={[action]}
            name={"allocation"}
            onChange={(val: any[]) => {
              setAction(val[0]);
            }}
            options={ACTION_OPTIONS}
          />
        </div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    </FormPopup>
  );
};
