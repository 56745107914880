import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { LinkableTable } from "../../components/linkable-table";
import { JsonBlob } from "../../components/json-blob";
import { AdminPermission, Column } from "../../domain";
import { api } from "../../util/api";
import { PermissionForm } from "./permission-form";

export const PermissionView = () => {
  let { id } = useParams<{ id: string }>();

  const [adminPermission, setAdminPermission] =
    useState<AdminPermission | null>(null);

  const [updatePermissionForm, setUpdatePermissionForm] =
    useState<boolean>(false);

  const [onSavePermissionForm, setOnSavePermissionForm] =
    useState<boolean>(false);

  useEffect(() => {
    api.getAdminPermissionById(id).then((adP: AdminPermission) => {
      setAdminPermission(adP);
    });
    if (onSavePermissionForm) {
      setOnSavePermissionForm(false);
    }
  }, [id, onSavePermissionForm]);
  return (
    <div className="partner-summaries">
      {updatePermissionForm && (
        <PermissionForm
          title="Edit permission"
          id={id}
          admin_group_id={`${adminPermission?.admin_group_id}`}
          adminPermission={adminPermission}
          onCancel={() => setUpdatePermissionForm(false)}
          onSave={() => setOnSavePermissionForm(true)}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <Card label={`Permission: ${id}`}>
            <div className="mb-4">
              <button
                className="btn btn-dark"
                onClick={() => {
                  setUpdatePermissionForm(true);
                }}
              >
                Edit permission
              </button>
            </div>
            <JsonBlob blob={adminPermission} />
          </Card>
        </div>
      </div>
    </div>
  );
};
