import React from "react";
import "./style.scss";

interface NavBarProps {
  userFullName: string;
  logout: () => void;
}
export const Navbar = (props: NavBarProps) => {
  return (
    <div className="navbar-container bg-white shadow">
      <nav className="navbar navbar-expand navbar-light topbar static-top ">
        <h2 className="m-0 ml-3">QuickExchange B2B Admin</h2>
      </nav>
      <div className="profile mr-3">
        <span className="mr-2">
          <i className="far fa-user"></i>
        </span>
        <span className="profile-full-name"> {props.userFullName} </span>
        <div className="profile-sub-menu shadow bg-white">
          <ul className="sub-menu m-0 p-0">
            <li className="sub-menu-item py-2 " onClick={() => props.logout()}>
              <span>
                <i className="fas fa-sign-out-alt mr-2"></i>
              </span>
              Logout
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
