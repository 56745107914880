import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./logo192.png";
import "./style.scss";

export const Divider = () => {
  return (
    <hr className="divider my-0"></hr>
  );
};


