import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { LinkableTable } from "../../components/linkable-table";
import { JsonBlob } from "../../components/json-blob";
import { AdminGroup, AdminPermission, Column, AdminAuth } from "../../domain";
import { api } from "../../util/api";
import { PermissionForm } from "../permission-view/permission-form";
import { Loading } from "../../components/loading";
import { UserForm } from "./user-form";

export const UserDetailView = () => {
  let { id } = useParams<{ id: string }>();

  const [user, setUser] = useState<AdminAuth | null>(null);
  const [updateUserForm, setUpdateUserForm] = useState<boolean>(false);
  const [onSaveUserForm, setOnSaveUserForm] = useState<boolean>(false);

  useEffect(() => {
    api.getUserById(id).then((u: AdminAuth) => {
      setUser(u);
    });
    if (onSaveUserForm) {
      setOnSaveUserForm(false);
    }
  }, [id, onSaveUserForm]);
  return (
    <div className="partner-summaries">
      {updateUserForm && (
        <UserForm
          title="Edit user"
          id={id}
          admin_group_id={`${user?.admin_group_id}`}
          onCancel={() => setUpdateUserForm(false)}
          onSave={() => setOnSaveUserForm(true)}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <Card label={`Admin User: ${user?.full_name}`}>
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setUpdateUserForm(true);
                  }}
                >
                  Edit user
                </button>
              </div>
            </div>
            <JsonBlob blob={user} />
          </Card>
        </div>
      </div>
    </div>
  );
};
