import React, { useState } from "react";
import Select from "react-dropdown-select";
import { LinkableTable } from "../../components/linkable-table";
import { ReputationIdentity } from "../../domain";
import { Transaction } from "../../domain/transaction";
import { api } from "../../util/api";
import "./style.css";
import { REPUTATION_KEY_TYPE } from "../../const/reputation";
import { Card } from "../../components/card";

// hack for shitty library
window.React = React;

const CreatedAtSorter = {
  desc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at < _b.created_at) {
        return 1;
      }
      if (_a.created_at > _b.created_at) {
        return -1;
      }
      return 0;
    });
  },

  asc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at > _b.created_at) {
        return 1;
      }
      if (_a.created_at < _b.created_at) {
        return -1;
      }
      return 0;
    });
  },
};

const columns = [
  {
    header: "Environment",
    key: "environment",
  },
  {
    header: "Summary",
    key: "summary",
  },
];

interface Option {
  label: REPUTATION_KEY_TYPE;
  value: REPUTATION_KEY_TYPE;
}

const STATUS_OPTIONS: Array<Option> = [
  ...Object.values(REPUTATION_KEY_TYPE).map((r) => ({ label: r, value: r })),
];

export const ReputationSearchView = () => {
  const [reputations, setReputations] = useState<Array<any> | null>(null);
  const [keyType, setKeyType] = useState<Option>({
    label: REPUTATION_KEY_TYPE.CORRELATION_KEY,
    value: REPUTATION_KEY_TYPE.CORRELATION_KEY,
  });
  const [keyValue, setKeyValue] = useState<string>("");

  const handleSearch = () => {
    if (keyType !== null && keyValue !== "") {
      api.getReputations(keyType.value, keyValue).then(setReputations);
    }
  };

  return (
    <div className="partner-summaries">
      <div className="row">
        <div className="col-md-12">
          <Card label="Reputation Search">
            <div className="row">
              <div className="col-md-4">
                <label>Key Type</label>
                <Select
                  className="form-control"
                  values={[keyType]}
                  onChange={(val: any[]) => {
                    setKeyType(val[0]);
                  }}
                  options={STATUS_OPTIONS}
                />
              </div>
              <div className="col-md-4">
                <label>Key Value</label>
                <input
                  type="text"
                  value={keyValue}
                  style={{ display: "block" }}
                  className="form-control"
                  onChange={(event) => {
                    setKeyValue(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label>&nbsp;</label>
                <br />
                <button
                  onClick={handleSearch}
                  disabled={keyType === null || keyValue === ""}
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                {reputations === null ? (
                  <p>Enter a search term</p>
                ) : (
                  <LinkableTable
                    idUrl="/reputation/:id"
                    data={reputations}
                    columns={columns}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
