import React from "react";
import "./style.css";

interface FormPopupProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  modalSize?: "modal-lg" | "modal-sm" | "modal-md";
  onSubmit?: () => void;
  onCancel: () => void;
}

export const FormPopup = (props: FormPopupProps): React.ReactElement => {
  const { title, children, onSubmit, onCancel } = props;
  const handleFormClick = (e: any) => {
    e.stopPropagation();
  };
  return (
    <div
      className={`modal-open modal  ${props.className} `}
      style={{ display: "block" }}
      onClick={onCancel}
    >
      <div
        className={`modal-dialog  ${props.modalSize}`}
        role="document"
        onClick={handleFormClick}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            {onSubmit ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={onSubmit}
              >
                Save changes
              </button>
            ) : null}

            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
