import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { JsonBlob } from "../../components/json-blob";
import { Loading } from "../../components";
import { TransactionLog } from "../../domain";
import { api } from "../../util/api";

export interface TransactionLogsProps {
  id: string;
  type:
    | "transaction-log"
    | "reputation-log"
    | "kyc-log"
    | "system-log"
    | "frontend-log";
}

export const TransactionLogView = (props: TransactionLogsProps) => {
  const [event, setEvent] = useState<TransactionLog | null>(null);
  const { type } = props;
  const { id } = useParams<{ id: string; type: string }>();

  useEffect(() => {
    switch (type) {
      case "transaction-log":
        api.getEvent(id).then(setEvent);
        break;
      case "reputation-log":
        api.getReputationLog(id).then(setEvent);
        break;
      case "system-log":
        api.getReputationLog(id).then(setEvent);
        break;
      case "frontend-log":
        api.getFrontendLog(id).then(setEvent);
        break;
    }
  }, []);

  if (event === null) {
    return (
      <Card label="Events">
        <Loading />
      </Card>
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <Card label={type}>
          <JsonBlob blob={event} />
        </Card>
      </div>
    </div>
  );
};
