import React, { useEffect, useState } from "react";
import "./style.scss";

export interface SortableTableFooterProps {
  offset: number;
  page: number;
  currentPage: number;
  fetchData: (page: number, offset: number) => void;
}
const initPages = (limit: number) => {
  const pagesAccept = limit > 6 ? 6 : limit;
  const pages: Array<number> = [];
  for (let i = 1; i <= pagesAccept; i++) {
    pages.push(i);
  }
  return pages;
};
export const SortableTableFooter = (props: SortableTableFooterProps) => {
  const { offset, page, currentPage, fetchData } = props;
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [pages, setPages] = useState<number[]>([]);

  const handleEntriesChange = (offset: number) => {
    fetchData(currentPage, offset);
    setToggleDropdown(false);
  };
  useEffect(() => {
    setPages(initPages(page));
  }, [page]);
  const handleNavigate = (control: string) => {
    if (control === "Previous" && currentPage !== 0) {
      if (currentPage === pages[0] && currentPage !== 1) {
        setPages([currentPage - 1, ...pages.slice(0, pages.length - 1)]);
      }
      fetchData(currentPage - 1, offset);
      return;
    }
    if (control === "Next" && currentPage !== page) {
      if (currentPage === pages[pages.length - 1]) {
        setPages([...pages.slice(1, pages.length), currentPage + 1]);
      }
      fetchData(currentPage + 1, offset);
      return;
    }
    return;
  };
  const renderPagination = () => {
    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination  justify-content-end">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              className="page-link"
              href="#"
              onClick={() => handleNavigate("Previous")}
            >
              Previous
            </a>
          </li>
          {pages.map((item) => {
            const activeClass = item === currentPage ? "bg-info disabled" : "";
            return (
              <li
                className={`page-item ${activeClass}`}
                key={item}
                onClick={() => fetchData(item, offset)}
              >
                <a className={`page-link ${activeClass}`} href="#">
                  {item}
                </a>
              </li>
            );
          })}
          <li className={`page-item ${currentPage === page ? "disabled" : ""}`}>
            <a
              className="page-link"
              href="#"
              onClick={() => handleNavigate("Next")}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    );
  };
  return (
    <div className="row sort-table-footer">
      <div className="col-5">
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-expanded="false"
            onClick={() => setToggleDropdown(!toggleDropdown)}
          >
            {offset}
          </button>
          <ul className={`dropdown-menu ${toggleDropdown ? "show" : ""}`}>
            <li
              className="dropdown-item"
              onClick={() => handleEntriesChange(20)}
            >
              20
            </li>
            <li
              className="dropdown-item"
              onClick={() => handleEntriesChange(50)}
            >
              50
            </li>
            <li
              className="dropdown-item"
              onClick={() => handleEntriesChange(100)}
            >
              100
            </li>
          </ul>
        </div>
      </div>
      <div className="col-7">{renderPagination()}</div>
    </div>
  );
};
