import React, { useState } from "react";
import Select from "react-dropdown-select";
import { FormPopup } from "../../components/form-popup";
import { PARTNER_CONFIG_PARAMETER } from "../../const";
import { PARTNER_ENVIRONMENT } from "../../const/status";
import {
  PartnerConfig,
  PartnerConfigBlob,
  SelectOption,
  Transaction,
} from "../../domain";
import { api } from "../../util/api";
import { CreatePartnerRequest } from "./types";

interface PartnerFormProps {
  title: string;
  partnerConfig: PartnerConfig | null;
  onCancel: () => void;
  onSave: () => void;
}
const ENV_OPTIONS: Array<SelectOption> = Object.keys(PARTNER_ENVIRONMENT).map(
  (s) => ({ label: s, value: s })
);
export const PartnerForm = (props: PartnerFormProps) => {
  const { onCancel, onSave } = props;
  const [partner, setPartner] = useState<CreatePartnerRequest>({
    label: props.partnerConfig?.label || "",
    environment: props.partnerConfig?.environment || "",
    config_blob: {
      [PARTNER_CONFIG_PARAMETER.FTX_TENANT]:
        props.partnerConfig?.config_blob[PARTNER_CONFIG_PARAMETER.FTX_TENANT] ||
        "",
      [PARTNER_CONFIG_PARAMETER.HELP_URL]:
        props.partnerConfig?.config_blob[PARTNER_CONFIG_PARAMETER.HELP_URL] ||
        "",
      [PARTNER_CONFIG_PARAMETER.SYNC_TO_FTX_PRODUCTION]:
        props.partnerConfig?.config_blob[
          PARTNER_CONFIG_PARAMETER.SYNC_TO_FTX_PRODUCTION
        ] || "false",
    },
  });
  const handlePartnerChange =
    (name: any) => (event: { target: { value: any } }) => {
      setPartner({ ...partner, [name]: event.target.value });
    };

  const handleFormClick = async () => {
    if (props.partnerConfig?.id) {
      try {
        await api
          .putUpdatePartner(props.partnerConfig.partner_id, {
            ...partner,
            environment: environment.value,
          } as CreatePartnerRequest)
          .then((response) => {
            onSave();
          });
      } catch (error) {
        setErrorMessage("Somethings went wrong!");
      }
    } else {
      try {
        await api
          .postCreatePartner({
            ...partner,
            environment: environment.value,
          })
          .then((response) => {
            onSave();
          });
      } catch (error) {
        setErrorMessage("Somethings went wrong!");
      }
    }
  };
  const defaultEnv = (props: PartnerFormProps): number => {
    for (let index = 0; index < ENV_OPTIONS.length; index++) {
      if (props.partnerConfig?.environment === ENV_OPTIONS[index].value) {
        return index;
      }
    }
    return 0;
  };
  const [environment, setEnvironment] = useState<SelectOption>(
    ENV_OPTIONS[defaultEnv(props)]
  );
  const [errorMessage, setErrorMessage] = useState<any>(null);
  return (
    <FormPopup
      title={props.title}
      onSubmit={handleFormClick}
      onCancel={onCancel}
    >
      <div className="row">
        {errorMessage && (
          <div className="col-md-12 mb-2">
            <p style={{ color: "red" }}>{errorMessage}</p>
          </div>
        )}
        <div className="col-md-12 mb-2">
          <label>Name</label>
          <input
            type="text"
            name={"partner_name"}
            key={"partner_name"}
            style={{ display: "block" }}
            className="form-control"
            value={partner.label}
            onChange={handlePartnerChange("partner_name")}
          />
        </div>

        <div className="col-md-12 mb-2">
          <label>Environment</label>
          <Select
            className="form-control"
            values={[environment]}
            name={"environment"}
            onChange={(val: any[]) => {
              setEnvironment(val[0]);
            }}
            options={ENV_OPTIONS}
          />
        </div>
        <ConfigForm
          configBlob={partner.config_blob}
          onChange={(config: PartnerConfigBlob) => {
            setPartner({ ...partner, config_blob: config });
          }}
        />
      </div>
    </FormPopup>
  );
};

const ConfigForm = (props: {
  configBlob: PartnerConfigBlob;
  onChange: (val: PartnerConfigBlob) => void;
}) => {
  const { configBlob, onChange } = props;
  const handleConfigChange =
    (pcp: PARTNER_CONFIG_PARAMETER) => (event: { target: { value: any } }) => {
      onChange({
        ...configBlob,
        [pcp]: event.target.value,
      });
    };

  return (
    <div>
      {Object.values(PARTNER_CONFIG_PARAMETER).map((pcp) => (
        <div className="col-md-12 mb-2">
          <label>{pcp}</label>
          <input
            type="text"
            name={pcp}
            key={pcp}
            style={{ display: "block" }}
            className="form-control"
            value={configBlob[pcp]}
            onChange={handleConfigChange(pcp)}
          />
        </div>
      ))}
    </div>
  );
};
