import React, { useEffect, useState } from "react";
import "./style.css";
import { Card } from "../../components/card";
import { randStr } from "../../util/helpers";
import {
  ComposeForm,
  ComposeFormField,
  FIELD_TYPE,
} from "../../components/compose-form";
import Axios from "axios";
import { api } from "../../util/api";
import { LinkableTable } from "../../components/linkable-table";
import { JsonBlob } from "../../components";
import { PARTNER_ENVIRONMENT } from "../../const";

export const TestSingpassView = () => {
  const [form, setForm] = useState<any>({
    environment: PARTNER_ENVIRONMENT.PRODUCTION,
    code: "",
    state: "",
    fetch: "access_code",
  });
  const [result, setResult] = useState<any>(undefined);

  const handleSubmit = () => {
    setResult(null);
    switch (form.fetch) {
      case "access_code":
        return api.benchtestAccesstoken(form).then(setResult);
      case "person_data":
        return api.benchtestPersonData(form).then(setResult);
    }
  };

  const formFields: Array<ComposeFormField> = [
    {
      label: "Fetch",
      id: "fetch",
      type: FIELD_TYPE.SELECT,
      options: [
        {
          label: "Access Token",
          value: "access_token",
        },
        {
          label: "Person Data",
          value: "person_data",
        },
      ],
    },
    {
      label: "Code",
      id: "code",
    },
    {
      label: "State",
      id: "state",
    },
    {
      label: "Environement",
      id: "environment",
      type: FIELD_TYPE.SELECT,
      options: [
        {
          label: "PRODUCTION",
          value: "PRODUCTION",
        },
        {
          label: "SANDBOX",
          value: "SANDBOX",
        },
      ],
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Test">
            <ComposeForm
              fields={formFields}
              value={form}
              onChange={setForm}
              onSubmit={handleSubmit}
            />
          </Card>
        </div>
      </div>
      {result !== undefined ? (
        <div className="row">
          <div className="col-lg-12">
            <Card label="Test">
              <JsonBlob blob={result} />
            </Card>
          </div>
        </div>
      ) : null}
    </div>
  );
};
