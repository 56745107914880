import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { JsonBlob } from "../../components/json-blob";
import { Loading } from "../../components";
import { Column, SystemLog, TransactionLog } from "../../domain";
import { api } from "../../util/api";
import { LinkableTable } from "../../components/linkable-table";

export const SystemLogDetailView = () => {
  const [systemLog, setSystemLog] = useState<SystemLog| null>(null);
  const { id } = useParams<{ id: string; type: string }>();
  useEffect(() => {
   api.getSystemLogById(id).then(setSystemLog)
  }, []);

  if (systemLog === null) {
    return (
      <Card label="System Log Detail">
        <Loading />
      </Card>
    );
  }
  return (
    <div className="row">
      <div className="col-lg-12">
        <Card label='System Log Detail'>
          <JsonBlob blob={systemLog} />
        </Card>
      </div>
    </div>
  );
};
