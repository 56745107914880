export const uuidLabel = (uuid?: string | null) => {
  if (uuid === null || uuid === undefined) {
    return "";
  }
  return uuid.substr(0, 8);
};
const isDateFormat = (date: string)  => {
  const dateFormat = /^\d{4}-\d{2}-\d{2}.*/;
  return dateFormat.test(date);
}
export const formatDate = (ts: number | string): string => {
  const ts_num = typeof ts === "number" ? ts : isDateFormat(ts) ? ts : parseInt(ts);
  const dt = new Date(ts_num);
  const y = dt.getFullYear();
  const M = dt.getMonth() + 1;
  const d = dt.getDate();
  const h = dt.getHours();
  const m = dt.getMinutes();
  const s = dt.getSeconds();
  return `${y}-${M}-${d} ${h}:${m}:${s}`;
};
