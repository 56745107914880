import React, { useEffect, useState } from "react";
import "./style.css";
import { Card } from "../../components/card";
import { randStr } from "../../util/helpers";
import {
  ComposeForm,
  ComposeFormField,
  FIELD_TYPE,
} from "../../components/compose-form";
import Axios from "axios";
import { api } from "../../util/api";
import { LinkableTable } from "../../components/linkable-table";
import { SessionLaunchForm } from "./launch-form";

interface TestForm {
  email?: string;
  objective: string;
  callback_url?: string;
  jurisdiction?: string;
  redirect_url?: string;
  correlation_key: string;
  full_legal_name?: string;
  tenant?: string;

  push_to_ftx: boolean;
}

export const TestSessionView = () => {
  const [form, setForm] = useState<any>({
    correlation_key: randStr(20),
    objective: "trade_otc",
    push_to_ftx: false,
    tenant: "grab_sandbox",
  });
  const [tests, setTests] = useState<any>([]);
  const [partners, setPartner] = useState<any>(null);
  const [newSession, setNewSession] = useState<any>(null);

  const handleSubmit = () => {
    api.benchtestSession(form).then((result) => {
      setTests([...tests, result]);
      setNewSession(result);
    });
  };

  const handleUpdate = (val: any) => {
    console.log(val);
    setForm(val);
  };

  const handleRandom = () => {
    setForm({
      ...form,
      email: randStr(10) + "@test.com",
      correlation_key: randStr(20),
    });
  };

  useEffect(() => {
    api.getApiKeys().then((keys) => {
      form.public_api_key = keys[0].public_api_key;
      setPartner(keys);
    });
  }, []);

  const formFields: Array<ComposeFormField> = [
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Objective",
      id: "objective",
    },
    {
      label: "Callback Url",
      id: "callback_url",
    },
    {
      label: "Jurisdiction",
      id: "jurisdiction",
    },
    {
      label: "Redirect Url",
      id: "redirect_url",
    },
    {
      label: "Correlation Key",
      id: "correlation_key",
    },
    {
      label: "Full Legal Name",
      id: "full_legal_name",
    },
    {
      label: "Partner",
      id: "public_api_key",
      type: FIELD_TYPE.SELECT,
      options:
        partners !== null
          ? partners.map((p: any) => ({
              label: p.p_label,
              value: p.public_api_key,
            }))
          : [],
    },
  ];

  return (
    <div>
      {newSession !== null ? (
        <SessionLaunchForm
          onCancel={() => setNewSession(null)}
          session={newSession}
          title="New Session"
        />
      ) : null}
      <div className="row">
        <div className="col-lg-12">
          <Card label="Test">
            <button type="button" onClick={handleRandom}>
              Random
            </button>
            <ComposeForm
              fields={formFields}
              value={form}
              onChange={handleUpdate}
              onSubmit={handleSubmit}
            />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Test">
            <LinkableTable
              columns={[]}
              idField={"user_session_id"}
              idUrl={"/session/:id"}
              data={tests}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
