import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { LoginViewContainer } from "./containers/login-view";
import { SideBar } from "./components/sidebar";
import { connect } from "react-redux";
import { AppState } from "./store";
import { MenuItem } from "./components/sidebar/types";
import { Navbar } from "./components/navbar";
import { routes } from "./routes";

import "./types.d.ts";
import "./App.css";
import "./style.scss";
import { Dispatch } from "redux";
import { logout, logoutAction } from "./store/auth";

export interface AppProps {
  login: any | null;
  logout: () => void;
}

const menu: Array<MenuItem> = [
  {
    label: "Partners",
    icon: <i className="fas fa-users"></i>,
    url: "/",
  },
  {
    label: "Reporting",
    icon: <i className="far fa-chart-bar"></i>,
    url: "#",
    items: [  
      {
        label: "System Logs",
        url: "/report/system_logs",
      },
    ],
  },
  {
    label: "Reputations",
    icon: <i className="fas fa-link"></i>,
    url: "/reputation",
  },
  {
    label: "Bench Test",
    icon: <i className="fas fa-vial"></i>,
    url: "#",
    items: [
      {
        label: "Session",
        url: "/test/session",
      },
      {
        label: "Singpass",
        url: "/test/singpass",
      },
      {
        label: "FTX",
        url: "/test/ftx",
      },
    ],
  },
  {
    label: "Admin",
    icon: <i className="fas fa-user-shield"></i>,
    url: "#",
    items: [
      {
        label: "Users",
        url: "/admin/users",
      },
      {
        label: "Groups",
        url: "/admin/groups",
      },
    ],
  },
];

export const App = (props: AppProps) => {
  // const routeResult = useRoutes(routes);
  const { login, logout } = props;
  if (login === null) {
    return <LoginViewContainer />;
  }
  return (
    <div id="wrapper">
      <Router>
        <SideBar items={menu} />
        <div id="content-wrapper" className="d-flex flex-column">
          <Navbar userFullName={login.name} logout={logout} />
          <div className="content-panel">
            <Switch>
              {Object.keys(routes).map((r) => (
                <Route
                  key={r}
                  exact
                  path={r}
                  render={(routes as any)[r as any] as any}
                ></Route>
              ))}
            </Switch>
          </div>
        </div>
      </Router>
      <div id="api_error_id" style={{ zIndex: 1100 }}></div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    login: state.auth.login,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    logout: () => dispatch(logoutAction()),
  };
};
export const AppContainer = connect<any, any, any, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(App);
