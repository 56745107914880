import React, { useRef, useEffect, useState, CSSProperties } from "react";
import echarts from "echarts";

export interface EChartLegend {
  data: Array<string>;
}

export interface ECHartSeriesGraphNode {
  name: string;
  value?: any;
  category: number;
  id: number | string;
}

export interface ECHartSeriesGraphCatergory {
  id?: number | string;
  name: string;
  keyword: any;
  base?: string;
}

export interface ECHartSeriesGraphEdge {
  source: string | number;
  target: string | number;
}
export interface ECHartSeriesPie {
  name?: string;
  type: "pie";
  radius?: string;
  data: Array<{ value: number; name: string }>;
  emphasis?: {
    itemStyle: {
      shadowBlur: 10;
      shadowOffsetX: 0;
      shadowColor: "rgba(0, 0, 0, 0.5)";
    };
  };
}
export interface ECHartSeriesBar {
  name?: string;
  type: "bar";
  data: Array<number | any>;
  barWidth?: string | number;
  barMaxWidth?: string | number;
  stack?: string;
  emphasis?: any;
  itemStyle?: {
    color?: string;
  };
}
export interface ECHartSeriesLine {
  name?: string;
  type: "line";
  yAxisIndex?: number;
  xAxisIndex?: number;
  data: Array<number | any>;
  smooth?: boolean;
  stack?: string;
  emphasis?: any;
  itemStyle?: {
    color?: string;
  };
}
export interface ECHartSeriesGraph {
  name?: string;
  type: "graph";
  layout: string;
  animation?: boolean;
  label: {
    show?: boolean;
    position: string;
    formatter: string;
  };
  draggable?: boolean;
  roam?: boolean;
  cursor?: string;
  data: Array<ECHartSeriesGraphNode | number>;
  categories: Array<ECHartSeriesGraphCatergory>;
  force: {
    edgeLength: number;
    repulsion: number;
    gravity: number;
  };
  edges: Array<ECHartSeriesGraphEdge>;
}

export type ECHartSeries =
  | ECHartSeriesGraph
  | ECHartSeriesBar
  | ECHartSeriesPie
  | ECHartSeriesLine;

export interface EChartAxis {
  type: string;
  data?: Array<string | number | Date>;
  axisTick?: {
    alignWithLabel: true;
  };
  axisLabel?: {
    formatter?: (v: any) => string;
    rotate?: number;
  };
  boundaryGap?: boolean;
  min?: Date;
  max?: Date;
}

export interface EChartOptions {
  title?: {
    text: string;
  };
  legend?: EChartLegend;
  series: Array<ECHartSeries>;
  tooltip?: {
    trigger: "axis" | "item";
    axisPointer: {
      type: "line" | "shadow";
    };
    formatter?: (v: any) => string | number;
  };
  grid?: {
    left: string;
    right: string;
    bottom: string;
    containLabel: boolean;
  };
  xAxis?: Array<EChartAxis>;
  yAxis?: Array<EChartAxis>;
}

export interface EChartProps {
  options: EChartOptions;
  eventHandlers?: { [event: string]: (params: any) => void };
  style?: CSSProperties;
  className?: string;
}

export const EChart = (props: EChartProps) => {
  const [elem, setElem] = useState<HTMLDivElement | null>(null);

  const { options, eventHandlers } = props;
  const [chart, setChart] = useState<any>(null);
  const prevOptions = useRef("");

  useEffect(() => {
    if (elem !== null && elem !== undefined && chart === null) {
      var myChart = echarts.init(elem);
      setChart(myChart);
    }
  }, [elem, chart]);

  useEffect(() => {
    const hash = JSON.stringify(options);
    if (chart !== null && chart !== undefined && prevOptions.current !== hash) {
      prevOptions.current = hash;
      chart.setOption(options);
      if (eventHandlers !== undefined) {
        for (const event of Object.keys(eventHandlers)) {
          chart.on(event, eventHandlers[event]);
        }
      }
    }
  }, [chart, options, eventHandlers]);

  return (
    <div ref={setElem} style={props.style} className={props.className}>
      TEST
    </div>
  );
};
