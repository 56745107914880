import React, { Component } from "react";
import { SelectOption } from "../../../domain";
import { SearchData } from "./types";
interface SortableTableHeaderFilterProps {
  search: SearchData;
  options: SelectOption[];
  headerProps: any;
  style: any;
  onChange: (search: SearchData) => void;
}
export class SortableTableHeaderFilter extends Component<SortableTableHeaderFilterProps> {
  static defaultProps = {
    headerProps: {},
  };

  onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange({
      field: this.props.search.field,
      value: event.target.value,
    });
  };

  render() {
    const { options } = this.props;
    return (
      <td style={this.props.style} {...this.props.headerProps}>
        <select
          name={this.props.search.field}
          onChange={this.onChange}
          style={{ height: "21px" }}
        >
          {options.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            );
          })}
        </select>
      </td>
    );
  }
}
