export enum TRANSACTION_EVENT {
  TRANSACTION_CREATED = "TRANSACTION_CREATED",

  SETTLEMENT_CLONED = "SETTLEMENT_CLONED",
  REQUOTE_ACCEPTED = "REQUOTE_ACCEPTED",
  REQUOTE_BROKEN = "REQUOTE_BROKEN",

  FUNDING_INSTRUCTION_CREATED = "FUNDING_INSTRUCTION_CREATED",
  FUNDING_READY_TO_TAKE = "FUNDING_READY_TO_TAKE",
  FUNDING_WILL_TAKE = "FUNDING_WILL_TAKE",
  FUNDING_TAKEN = "FUNDING_TAKEN",
  FUNDING_INFLIGHT = "FUNDING_INFLIGHT",
  FUNDING_HIGH_CONFIDENCE = "FUNDING_HIGH_CONFIDENCE",
  FUNDING_PROCESSING = "FUNDING_PROCESSING",
  FUNDING_WOULD_REJECT = "FUNDING_WOULD_REJECT",
  FUNDING_COMPLETED = "FUNDING_COMPLETED",
  FUNDING_USER_INPUT = "FUNDING_USER_INPUT",
  FUNDING_REJECTED = "FUNDING_REJECTED",
  FUNDING_BROKEN = "FUNDING_BROKEN",
  FUNDING_RETRY = "FUNDING_RETRY",
  FUNDING_BACKOUT = "FUNDING_BACKOUT",

  PAYOUT_READY_FOR_DELIVERY = "PAYOUT_READY_FOR_DELIVERY",
  PAYOUT_INSTRUCTION_CREATED = "PAYOUT_INSTRUCTION_CREATED",
  PAYOUT_STARTED = "PAYOUT_STARTED",
  PAYOUT_REQUEST_INTERNAL = "PAYOUT_REQUEST_INTERNAL",
  PAYOUT_REQUEST_EXTERNAL = "PAYOUT_REQUEST_EXTERNAL",
  PAYOUT_COMPLETED = "PAYOUT_COMPLETED",
  PAYOUT_INFLIGHT = "PAYOUT_INFLIGHT",
  PAYOUT_REQUIRED_RECOVERY = "PAYOUT_REQUIRED_RECOVERY",
  PAYOUT_REJECTED = "PAYOUT_REJECTED",
  PAYOUT_BROKEN = "PAYOUT_BROKEN",
  PAYOUT_SCHEDULE_RETRY = "PAYOUT_SCHEDULE_RETRY",
  PAYOUT_RETRY = "PAYOUT_RETRY",
  PAYOUT_USER_INPUT = "PAYOUT_USER_INPUT",

  ADMIN_SET_TRANSACTION_STATUS = "ADMIN_SET_TRANSACTION_STATUS",

  TIMEOUT = "TIMEOUT",

  QUOTE_EXPIRED_RECOVERABLE = "QUOTE_EXPIRED_RECOVERABLE",
  QUOTE_EXPIRED_UNRECOVERABLE = "QUOTE_EXPIRED_UNRECOVERABLE",
  QUOTE_PRE_APPROVAL_SUCCESS = "QUOTE_PRE_APPROVAL_SUCCESS",
  QUOTE_PRE_APPROVAL_FAILURE = "QUOTE_PRE_APPROVAL_FAILURE",

  HEDGE_QUOTE_CREATED = "HEDGE_QUOTE_CREATED",
  CONVERSION_ABORTED = "CONVERSION_ABORTED",
  CONVERSION_NEW_QUOTE = "CONVERSION_NEW_QUOTE",
  CONVERSION_READY = "CONVERSION_READY",
  CONVERSION_RETRY = "CONVERSION_RETRY",
  CONVERSION_RETRY_WITHOUT_PRE_APPROVAL = "CONVERSION_RETRY_WITHOUT_PRE_APPROVAL",
  CONVERSION_WILL_UNWIND = "CONVERSION_WILL_UNWIND",
  CONVERSION_UNWOUND = "CONVERSION_UNWOUND",
  CONVERSION_STARTED = "CONVERSION_STARTED",
  CONVERSION_PROCESSING = "CONVERSION_PROCESSING",
  CONVERSION_COMPLETED = "CONVERSION_COMPLETED",
  CONVERSION_FAILED = "CONVERSION_FAILED",
  CONVERSION_FAILED_PERMANENTLY = "CONVERSION_FAILED_PERMANENTLY",
  CONVERSION_RECOVER_AT_BEST = "CONVERSION_RECOVER_AT_BEST",

  SYNTHESIZER = "SYNTHESIZER",
}

export enum METHOD_EVENT {
  BLOCKCHAIN_TRANSACTION_BROADCASTED = "BLOCKCHAIN_TRANSACTION_BROADCASTED",
  BLOCKCHAIN_SINGLE_DEPOSIT_DETECTED = "BLOCKCHAIN_SINGLE_DEPOSIT_DETECTED",
  BLOCKCHAIN_SINGLE_DEPOSIT_CONFIRMED = "BLOCKCHAIN_SINGLE_DEPOSIT_CONFIRMED",
  BLOCKCHAIN_TOTAL_DEPOSITS_DETECTED = "BLOCKCHAIN_TOTAL_DEPOSITS_DETECTED",
  BLOCKCHAIN_TOTAL_DEPOSITS_CONFIRMED = "BLOCKCHAIN_TOTAL_DEPOSITS_CONFIRMED",
  BLOCKCHAIN_TOTAL_DEPOSITS_EXCESS = "BLOCKCHAIN_TOTAL_DEPOSITS_EXCESS",
  BLOCKCHAIN_TOTAL_DEPOSITS_INSUFFICIENT = "BLOCKCHAIN_TOTAL_DEPOSITS_INSUFFICIENT",
  BLOCKCHAIN_SYNTHETIC_DEPOSIT_CREATED = "BLOCKCHAIN_SYNTHETIC_DEPOSIT_CREATED",
  BLOCKCHAIN_SYNTHETIC_DEPOSIT_CONFIRMED = "BLOCKCHAIN_SYNTHETIC_DEPOSIT_CONFIRMED",
  BLOCKCHAIN_CLIENT_TRANSACTION_BROADCASTED = "BLOCKCHAIN_CLIENT_TRANSACTION_BROADCASTED",

  BLOCKCHAIN_PAYOUT_QUEUED = "BLOCKCHAIN_PAYOUT_QUEUED",

  USER_BLOCKED = "USER_BLOCKED",
  CARD_TOKENIZED = "CARD_TOKENIZED",
  CARD_3DS_RESULT = "CARD_3DS_RESULT",
  CARD_3DS_SUCCESS = "CARD_3DS_SUCCESS",
  CARD_3DS_FAILURE = "CARD_3DS_FAILURE",
  CARD_CHARGE_SUCCESS = "CARD_CHARGE_SUCCESS",
  CARD_AUTHORISATION_SUCCEEDED = "CARD_AUTHORISATION_SUCCEEDED",
  IP_UNTRUSTWORTHY = "IP_UNTRUSTWORTHY",
  COUNTRY_NOT_ALLOWED = "COUNTRY_NOT_ALLOWED",
  USER_USDE_LIMIT_48_HOUR = "USER_USDE_LIMIT_48_HOUR",
  USER_TXNS_LIMIT_48_HOUR = "USER_TXNS_LIMIT_48_HOUR",
  CARD_USDE_LIMIT_48_HOUR = "CARD_USDE_LIMIT_48_HOUR",
  CARD_TXNS_LIMIT_48_HOUR = "CARD_TXNS_LIMIT_48_HOUR",
  USER_USDE_LIMIT_2_HOUR = "USER_USDE_LIMIT_2_HOUR",
  USER_TXNS_LIMIT_2_HOUR = "USER_TXNS_LIMIT_2_HOUR",
  CARD_USDE_LIMIT_2_HOUR = "CARD_USDE_LIMIT_2_HOUR",
  CARD_TXNS_LIMIT_2_HOUR = "CARD_TXNS_LIMIT_2_HOUR",
  USER_CARDS_LIMIT_365_DAY = "USER_CARDS_LIMIT_365_DAY",
  USER_CARDS_LIMIT_28_DAY = "USER_CARDS_LIMIT_28_DAY",
  USER_COOL_OFF_SCHEDULE = "USER_COOL_OFF_SCHEDULE",
  VOID_CARD_PAYMENT = "VOID_CARD_PAYMENT",
  BILLING_ADDRESS_INVALID = "BILLING_ADDRESS_INVALID",
  FRAUD_CHECK_RESULT = "FRAUD_CHECK_RESULT",
  CARD_REJECTED_BY_ISSUER = "CARD_REJECTED_BY_ISSUER",
  TRANSACTION_REJECTED_BY_ISSUER = "TRANSACTION_REJECTED_BY_ISSUER",
  THIRD_PARTY_ERROR = "THIRD_PARTY_ERROR",
  ISSUER_REJECTED_BY_LIQUID = "ISSUER_REJECTED_BY_LIQUID",
  GLOBAL_BLACKLIST = "GLOBAL_BLACKLIST",

  LIQUID_PAYOUT_REQUESTED = "LIQUID_PAYOUT_REQUESTED",
  LIQUID_PAYOUT_INTERNAL = "LIQUID_PAYOUT_INTERNAL",
  LIQUID_PAYOUT_EXTERNAL = "LIQUID_PAYOUT_EXTERNAL",
  LIQUID_PAYOUT_CANCELLED = "LIQUID_PAYOUT_CANCELLED",
  MARKET_MOVE_AGAINST_USER = "MARKET_MOVE_AGAINST_USER",
  MARKET_MOVE_AGAINST_LIQUID = "MARKET_MOVE_AGAINST_LIQUID",

  FUNDING_INPUT_ACCEPTED = "FUNDING_INPUT_ACCEPTED",
  FUNDING_INPUT_REJECTED = "FUNDING_INPUT_REJECTED",
  FUNDING_EXTERNAL_REJECT = "FUNDING_EXTERNAL_REJECT",

  PAYOUT_INPUT_ACCEPTED = "PAYOUT_INPUT_ACCEPTED",
  PAYOUT_INPUT_REJECTED = "PAYOUT_INPUT_REJECTED",

  RECOVERY_WORKER_AUTO_BREAK = "RECOVERY_WORKER_AUTO_BREAK",
}
