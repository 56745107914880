import React, { Component } from "react";

interface IconProps {
  icon?: string;
  style?: any;
}

const FaIcon = (props: IconProps) => (
  <i className={`fa fa-lg ${props.icon}`} style={props.style} />
);

export const SortIconBoth = (props: IconProps) => (
  <FaIcon icon="fa-sort" style={props.style} />
);

export const SortIconAsc = (props: IconProps) => (
  <FaIcon icon="fa-sort-up" style={props.style} />
);

export const SortIconDesc = (props: IconProps) => (
  <FaIcon icon="fa-sort-down" style={props.style} />
);
