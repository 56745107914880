import axios from "axios";
import {
  TransactionLog,
  TransactionLogQuery,
  RawTransaction,
  Transaction,
  ReputationGraph,
  ReputationIdentity,
  KycIdentity,
  PartnerStatistics,
  PartnerConfig,
  Currencies,
  CurrencyGroup,
  CurrencyDetail,
  PartnerCurrencyGroup,
  PartnerCurrencies,
  PartnerDependency,
  InventoryBalance,
  InventoryDelta,
  AdminPermission,
  AdminGroup,
  AdminAuth,
} from "../domain";

import { ONE_HOUR, TRANSACTION_STATUS } from "../const";
import { CreatePartnerRequest } from "../containers/partner-view/types";

import { CreatePartnerDependencyRequest } from "../containers/partner-dependency-view/types";

import { CreatePermissionRequest } from "../containers/permission-view/types";
import {
  CreateGroupRequest,
  DeletePermissionsRequest,
} from "../containers/group-detail-view/types";
import { UpdateUserRequest } from "../containers/user-detail-view/types";
import { ErrorMessage } from "../components/error-message";
import React from "react";
import ReactDOM from "react-dom";
interface QueryParams {
  [key: string]: string | number | undefined;
}

export const publicUrlBase = process.env.REACT_APP_PUBLIC_URL_BASE;

export class Api {
  urlBase = process.env.REACT_APP_BACKEND_URL_BASE;
  bearer: string | null = null;

  setAuth(bearer: string) {
    this.bearer = bearer;
  }

  postTokensignin(id_token: any) {
    return this.post("/api/v1/auth/tokensignin", { id_token });
  }
  async postCreatePartner(
    parameters: CreatePartnerRequest
  ): Promise<PartnerConfig> {
    return this.post("/api/v1/partner/", parameters);
  }

  async putUpdatePartner(
    partner_id: string,
    parameters: CreatePartnerRequest
  ): Promise<PartnerConfig> {
    return this.put(`/api/v1/partner/${partner_id}`, parameters);
  }
  getPartners(): Promise<Array<PartnerConfig>> {
    return this.get("/api/v1/partner/");
  }
  getApiKeys(): Promise<Array<any>> {
    return this.get("/api/v1/partner/api_keys");
  }
  getPartner(partnerId: string) {
    return this.get(`/api/v1/partner/${partnerId}`);
  }
  getPartnerConfig(partnerId: string) {
    return this.get(`/api/v1/partner/${partnerId}/config`);
  }
  getPartnerApiKeys(partnerId: string) {
    return this.get(`/api/v1/partner/${partnerId}/api_keys`);
  }
  getPartnerConfigById(id: string) {
    return this.get(`/api/v1/partner/config/${id}`);
  }
  getPartnerStatistics(partnerId: string) {
    return this.get(`/api/v1/partner/${partnerId}/statistics`);
  }
  async getPartnerReputations(partnerId: string, query?: QueryParams) {
    const transactions: Array<RawTransaction> = await this.get(
      `/api/v1/partner/${partnerId}/reputations`,
      query
    );
    return transactions.map(
      (t: RawTransaction): Transaction => ({
        ...t,
        created_at: parseInt(t.created_at),
      })
    );
  }

  async getReputations(type: string, value: string) {
    const transactions: Array<RawTransaction> = await this.get(
      `/api/v1/reputation`,
      {
        type,
        value,
      }
    );
    return transactions.map(
      (t: RawTransaction): Transaction => ({
        ...t,
        created_at: parseInt(t.created_at),
      })
    );
  }
  getSystemLogs() {
    return this.get("/api/v1/system-logs");
  }

  getSystemLogById(id: string) {
    return this.get(`/api/v1/system-logs/${id}`);
  }
  benchtestSession(form: any) {
    return this.post("/api/v1/bench_test/session", form);
  }
  benchtestFtx(form: any) {
    return this.post("/api/v1/bench_test/ftx/kyc_history", form);
  }
  benchtestAccesstoken(form: any) {
    return this.post("/api/v1/bench_test/singpass/access_token", form);
  }
  benchtestPersonData(form: any) {
    return this.post("/api/v1/bench_test/singpass/person_data", form);
  }
  getSession(id: string) {
    return this.get("/api/v1/session/" + id);
  }
  getSessionLogs(id: string) {
    return this.get("/api/v1/session/" + id + "/logs");
  }
  getSessionHandoff(id: string) {
    return this.get("/api/v1/session/" + id + "/handoff");
  }
  getSessionLoginStatus(id: string) {
    return this.get("/api/v1/session/" + id + "/login_status");
  }
  getSessionKycHistory(id: string) {
    return this.get("/api/v1/session/" + id + "/kyc_history");
  }
  getSessionFtxPull(id: string, force?: boolean) {
    return this.post("/api/v1/session/" + id + "/ftx_pull?force=" + force);
  }
  getSessionFtxPush(id: string) {
    return this.post("/api/v1/session/" + id + "/ftx_push");
  }
  getTransaction(id: string) {
    return this.get("/api/v1/transaction/" + id);
  }
  getTransactionOutcome(id: string) {
    return this.get(`/api/v1/transaction/${id}/outcome`);
  }
  getTransactionReputationKeys(id: string) {
    return this.get(`/api/v1/transaction/${id}/reputation_keys`);
  }
  getTransactionStatusSequence(id: string) {
    return this.get(`/api/v1/transaction/${id}/status_sequence`);
  }
  getTransactionFrontendLogs(id: string) {
    return this.get(`/api/v1/transaction/${id}/frontend-logs`);
  }
  getTransactionRequote(id: string, lockSide: string, purpose: string) {
    return this.get("/api/v1/transaction/" + id + "/requote", {
      lock: lockSide,
      purpose,
    });
  }
  putTransactionRequote(id: string, quoteId: string) {
    return this.put("/api/v1/transaction/" + id + "/requote", {
      quote_id: quoteId,
    });
  }
  getTakeFundsIfReady(id: string) {
    return this.put("/api/v1/transaction/" + id + "/takefunds");
  }
  retryTransactionConversion(id: string) {
    return this.get(`/api/v1/transaction/${id}/retry-conversion`);
  }
  getSettlementInstructions(transaction_id: string) {
    return this.get(
      `/api/v1/transaction/${transaction_id}/settlement-instructions`
    );
  }
  getTransactionQuotes(transaction_id: string) {
    return this.get(`/api/v1/transaction/${transaction_id}/quotes`);
  }
  getConversionAttempts(transaction_id: string) {
    return this.get(
      `/api/v1/transaction/${transaction_id}/conversion-attempts`
    );
  }
  putTransactionStatus(id: string, status: string, comment: string) {
    return this.put(`/api/v1/transaction/${id}/status`, {
      status,
      comment,
    });
  }
  putTransactionOutcome(
    id: string,
    status: string,
    comment: string,
    outcomeCategory: string,
    outcomeReason: string
  ) {
    return this.put(`/api/v1/transaction/${id}/outcome`, {
      status,
      comment,
      category: outcomeCategory,
      reason: outcomeReason,
    });
  }
  getQuote(quote_id: string) {
    return this.get(`/api/v1/quote/${quote_id}`);
  }
  getQuoteExecutionReports(quote_id: string) {
    return this.get(`/api/v1/quote/${quote_id}/execution-reports`);
  }
  getQuoteConversionAttempts(quote_id: string) {
    return this.get(`/api/v1/quote/${quote_id}/conversion-attempts`);
  }
  getQuotePreapprovals(quote_id: string) {
    return this.post(`/api/v1/quote/${quote_id}/preapprovals`);
  }
  postFetchNewPreapproval(quote_id: string) {
    return this.post(`/api/v1/quote/${quote_id}/preapproval`);
  }

  getSettlementInstruction(id: string) {
    return this.get("/api/v1/settlement/" + id);
  }
  getSettlementInstructionStatusSequence(id: string) {
    return this.get(`/api/v1/settlement/${id}/status_sequence`);
  }
  retrySettlementInstruction(id: string) {
    return this.get(`/api/v1/settlement/${id}/retry`);
  }
  syncSettlementInstruction(id: string) {
    return this.put(`/api/v1/settlement/${id}/sync-status`);
  }
  getSettlementAttempts(settlement_instruction_id: string) {
    return this.get(`/api/v1/settlement/${settlement_instruction_id}/attempts`);
  }
  getSettlementParameters(settlement_instruction_id: string) {
    return this.get(
      `/api/v1/settlement/${settlement_instruction_id}/parameters`
    );
  }
  getSettlementParameter(id: string) {
    return this.get("/api/v1/settlement/parameter/" + id);
  }
  getSettlementAttempt(id: string) {
    return this.get("/api/v1/settlement/attempt/" + id);
  }

  getConversionAttempt(id: string) {
    return this.get("/api/v1/conversion/" + id);
  }
  unwindConversionAttempt(id: string) {
    return this.post(`/api/v1/conversion/${id}/unwind`);
  }
  getConversionAttemptExecutionReport(id: string) {
    return this.get(`/api/v1/conversion/${id}/execution-report`);
  }
  getEvent(id: string): Promise<TransactionLog> {
    return this.get("/api/v1/transaction/log/" + id);
  }
  getEvents(query: TransactionLogQuery): Promise<Array<TransactionLog>> {
    return this.get("/api/v1/transaction/log/", query as any);
  }
  getReputation(id: string): Promise<ReputationGraph> {
    return this.get(`/api/v1/reputation/${id}`);
  }
  getReputationGraph(id: string): Promise<ReputationGraph> {
    return this.get(`/api/v1/reputation/${id}/graph`);
  }
  getReputationTransactions(id: string): Promise<any> {
    return this.get(`/api/v1/reputation/${id}/transactions`);
  }
  getReputationLogs(id: string): Promise<any> {
    return this.get(`/api/v1/reputation/${id}/logs`);
  }
  getReputationKycIdentities(id: string): Promise<any> {
    return this.get(`/api/v1/reputation/${id}/kyc-identities`);
  }
  getReputationLog(id: string): Promise<any> {
    return this.get(`/api/v1/reputation/log/${id}`);
  }
  getFrontendLog(id: string): Promise<any> {
    return this.get(`/api/v1/transaction/frontend-log/${id}`);
  }
  getReputationKeyGraph(id: string): Promise<ReputationGraph> {
    return this.get(`/api/v1/reputation/key/${id}/graph`);
  }
  getReputationSessions(id: string): Promise<any> {
    return this.get(`/api/v1/reputation/${id}/sessions`);
  }
  getKycState(id: string): Promise<any> {
    return this.get(`/api/v1/reputation/${id}/kyc_state`);
  }
  getKycDocuments(kycIdentityId: string): Promise<any> {
    return this.get(`/api/v1/reputation/${kycIdentityId}/documents`);
  }
  getKycDocument(id: string): Promise<any> {
    return this.get(`/api/v1/document/${id}`);
  }
  getKycDocumentStatus(kycDocumentId: string): Promise<any> {
    return this.get(`/api/v1/document/${kycDocumentId}/status`);
  }

  async getCurrencies(
    direction?: string,
    offset?: number,
    limit?: number,
    query?: any
  ): Promise<Currencies> {
    return this.get(`/api/v1/currency/currencies`, {
      direction,
      offset,
      limit,
      ...query,
    });
  }

  deleteCurrencies(ids: string[], groupId?: string): Promise<any> {
    return this.delete(
      `/api/v1/currency/currencies/${ids.join(",")}/currency_group/${groupId}`
    );
  }
  getCurrenciesByCurrencyGroupId(
    currency_group_id: string
  ): Promise<CurrencyDetail[]> {
    return this.get(
      `/api/v1/currency/currencies/currency_group/${currency_group_id}`
    );
  }
  getCurrencyGroups(): Promise<CurrencyGroup[]> {
    return this.get("/api/v1/currency/currency_group");
  }

  getCurrencyGroupById(currency_group_id: string): Promise<CurrencyGroup> {
    return this.get(`/api/v1/currency/currency_group/${currency_group_id}`);
  }

  getCurrencyById(currency_id: string): Promise<CurrencyDetail> {
    return this.get(`/api/v1/currency/${currency_id}`);
  }

  getPartnerCurrencyGroup(partner_id: string): Promise<PartnerCurrencyGroup[]> {
    return this.get(`/api/v1/partner/${partner_id}/partner_currency_groups`);
  }
  getPartnerCurrencies(partner_id: string): Promise<PartnerCurrencies[]> {
    return this.get(`/api/v1/partner/${partner_id}/currencies`);
  }

  getTransactionReportingList(min_ts?: number, max_ts?: number, query?: any) {
    return this.get(`/api/v1/report/transaction_reporting_list`, {
      min_ts,
      max_ts,
      ...query,
    });
  }
  getHeaders() {
    if (this.bearer === null) {
      return {};
    }
    return {
      Authorization: "Bearer " + this.bearer,
    };
  }
  getPartnerDependencies(
    partner_id: string
  ): Promise<Array<PartnerDependency>> {
    return this.get(`/api/v1/partner/${partner_id}/dependencies`);
  }
  getPartnerDependency(
    partner_id: string,
    id: string
  ): Promise<PartnerDependency> {
    return this.get(`/api/v1/partner/${partner_id}/dependency/${id}`);
  }
  postCreatePartnerDependency(
    partner_id: string,
    parameters: CreatePartnerDependencyRequest
  ) {
    return this.post(`/api/v1/partner/${partner_id}/dependency`, parameters);
  }
  putUpdatePartnerDependency(
    partner_id: string,
    partner_dependency_id: string,
    parameters: CreatePartnerDependencyRequest
  ) {
    return this.put(
      `/api/v1/partner/${partner_id}/dependency/${partner_dependency_id}`,
      parameters
    );
  }

  getInventoryBalance(id: string): Promise<InventoryBalance> {
    return this.get(`/api/v1/inventory/balance/${id}`);
  }

  getInventoryDelta(id: string): Promise<InventoryDelta> {
    return this.get(`/api/v1/inventory/delta/${id}`);
  }

  getCurrentInventory(vendor_id: string): Promise<Array<InventoryBalance>> {
    return this.get(`/api/v1/inventory/${vendor_id}/balance`);
  }

  getInventoryHistory(vendor_id: string): Promise<Array<InventoryBalance>> {
    return this.get(`/api/v1/inventory/${vendor_id}/balance_history`);
  }

  getEodDeltas(
    vendor_id: string,
    dateStr: string
  ): Promise<Array<InventoryDelta>> {
    return this.get(
      `/api/v1/inventory/${vendor_id}/end_of_day_delta/${dateStr}`
    );
  }

  getInventoryCurrencyHistory(
    vendor_id: string,
    currency: string
  ): Promise<Array<InventoryBalance>> {
    return this.get(
      `/api/v1/inventory/${vendor_id}/balance_history/${currency}`
    );
  }

  getAdminPermissions(admin_group_id: string): Promise<AdminPermission[]> {
    return this.get(`/api/v1/permission?admin_group_id=${admin_group_id}`);
  }
  getAdminPermissionById(id: string): Promise<AdminPermission> {
    return this.get(`/api/v1/permission/${id}`);
  }
  postCreatePermission(parameters: CreatePermissionRequest) {
    return this.post(`/api/v1/permission`, parameters);
  }
  putUpdatePermission(id: string, parameters: CreatePermissionRequest) {
    return this.put(`/api/v1/permission/${id}`, parameters);
  }

  getGroups(): Promise<AdminGroup[]> {
    return this.get(`/api/v1/permission/groups`);
  }
  getGroupById(id: string): Promise<AdminGroup> {
    return this.get(`/api/v1/permission/group/${id}`);
  }
  postCreateGroup(parameters: CreateGroupRequest) {
    return this.post(`/api/v1/permission/group`, parameters);
  }
  putUpdateGroup(id: string, parameters: CreateGroupRequest) {
    return this.put(`/api/v1/permission/group/${id}`, parameters);
  }

  getUsers(): Promise<AdminAuth[]> {
    return this.get(`/api/v1/permission/users`);
  }
  getUserById(id: string): Promise<AdminAuth> {
    return this.get(`/api/v1/permission/user/${id}`);
  }
  putUpdateUser(id: string, parameters: UpdateUserRequest) {
    return this.put(`/api/v1/permission/user/${id}`, parameters);
  }
  putDeletePermission(parameters: DeletePermissionsRequest) {
    return this.put(`/api/v1/permission/delete`, parameters);
  }
  async get(url: string, query?: QueryParams) {
    const result = await axios
      .get(this.urlBase + url, {
        params: query,
        headers: this.getHeaders(),
      })
      .catch((error) => {
        return Promise.resolve(error.response);
      });
    if (result && result.data && result.data.success) {
      return result.data.payload;
    }
    throw result?.data;
  }
  async put(url: string, data?: any) {
    const result = await axios
      .put(this.urlBase + url, data, {
        headers: this.getHeaders(),
      })
      .catch((error) => {
        handleError(error);
        return Promise.resolve(error.response);
      });
    if (result && result.data && result.data.success) {
      handleSuccess(result.data.message);
      return result.data.payload;
    }
    throw result?.data;
  }
  async post(url: string, data?: any) {
    const result = await axios
      .post(this.urlBase + url, data, {
        headers: this.getHeaders(),
      })
      .catch((error) => {
        handleError(error);
        return Promise.resolve(error.response);
      });
    if (result && result.data && result.data.success) {
      handleSuccess(result.data.message);
      return result.data.payload;
    }

    throw result?.data;
  }

  async delete(url: string, query?: QueryParams) {
    const result = await axios
      .delete(this.urlBase + url, {
        params: query,
        headers: this.getHeaders(),
      })
      .catch((error) => {
        handleError(error);
        return Promise.resolve(error.response);
      });
    if (result && result.data && result.data.success) {
      handleSuccess(result.data.message);
      return result.data.payload;
    }
    throw result?.data;
  }
}

function handleError(error: any) {
  if (error && error.response && error.response.data) {
    let messages = [error.response.statusText];
    if (error.response.data.reason) {
      messages = [error.response.data.reason];
    } else if (error.response.data.reasons) {
      messages = error.response.data.reasons.map((r: any) => r.message);
    }
    const html = React.createElement(ErrorMessage, {
      title: error.response.statusText,
      messages: messages,
      success: false,
    });
    const error_id = document.getElementById("api_error_id");
    if (error_id) {
      ReactDOM.render(React.createElement("div", {}, ""), error_id);
      ReactDOM.render(html, error_id);
    }
  }
}
function handleSuccess(message: string) {
  const html = React.createElement(ErrorMessage, {
    title: "Success",
    messages: [message || "Request successfully"],
    success: true,
  });
  const error_id = document.getElementById("api_error_id");
  if (error_id) {
    ReactDOM.render(React.createElement("div", {}, ""), error_id);
    ReactDOM.render(html, error_id);
  }
}
export const api = new Api();
