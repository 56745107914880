import React, { useEffect, useState } from "react";
import { Column, ReputationGraph } from "../../domain";
import "./style.css";
import { api } from "../../util/api";
import { Loading } from "../../components";
import { LinkableTable } from "../../components/linkable-table";
import { EChart } from "../../components/e-chart";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";

export interface ReputationKeyViewProps {
  id: string;
}

export interface ReputationKeyViewState {
  graph: ReputationGraph | null;
  transactions: Array<any> | null;
  logs: any;
}

const logsColumns: Array<Column> = [
  {
    header: "Primary event",
    key: "primary_event",
  },
  {
    header: "Context event",
    key: "context_event",
  },
];

export const ReputationKeyView = (props: ReputationKeyViewProps) => {
  const { id } = useParams<{ id: string }>();

  const [graph, setGraph] = useState<ReputationGraph | null>(null);

  useEffect(() => {
    api.getReputationKeyGraph(id).then(setGraph);
  }, []);
  const handleNodeClick = (nodeId: string) => {
    if (nodeId === id) {
      return;
    }
    if (graph !== null) {
      const identity = graph.identities.find((i: any) => i.id === nodeId);
      if (identity !== undefined) {
        window.location.href = `/reputation/${identity.id}`;
      }
    }
  };
  if (graph === null) {
    return <Loading />;
  }
  return (
    <div>
      <EChart
        style={{
          height: 500,
        }}
        eventHandlers={{
          click: ({ data }: any) => {
            if (data.category === 1 && data.id !== id) {
              handleNodeClick(data.id);
            }
          },
        }}
        options={{
          series: [
            {
              type: "graph",
              layout: "force",
              animation: false,
              label: {
                show: true,
                position: "right",
                formatter: "{b}",
              },
              draggable: true,
              roam: true,
              cursor: "pointer",
              data: [
                ...graph.identities.map((i) => ({
                  id: i.id,
                  //color: this.props.id === i.id ? "#44A" : "#AAB",
                  name: `Identity: ${i.id.substring(0, 8)}`,
                  category: 1,
                  value: i,
                })),
                ...graph.keys.map((k) => ({
                  id: k.id,
                  //color: "#4A4",
                  name: `${k.type.toLowerCase()}: ${k.value}`,
                  category: 2,
                })),
              ],
              categories: [
                { name: "HTMLElement", keyword: {}, base: "HTMLElement" },
                { name: "WebGL", keyword: {}, base: "WebGLRenderingContext" },
                { name: "SVG", keyword: {}, base: "SVGElement" },
                { name: "CSS", keyword: {}, base: "CSSRule" },
                { name: "Other", keyword: {} },
              ],
              force: {
                edgeLength: 50,
                repulsion: 200,
                gravity: 0.03,
              },
              edges: graph.relations.map((r) => ({
                source: r.reputation_identity_id,
                target: r.reputation_key_id,
              })),
            },
          ],
        }}
      />
    </div>
  );
};
