import React, { useEffect, useState } from "react";

import { Column } from "../../domain";
import { api } from "../../util/api";
import { Loading } from "../../components";
import { LinkableTable } from "../../components/linkable-table";
import { uuidLabel } from "../../util/labels";
import { JsonBlob } from "../../components/json-blob";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";

const logsColumns: Array<Column> = [
  {
    header: "Status",
    key: "status",
  },
];

const documentsColumns: Array<Column> = [
  {
    header: "element",
    key: "element",
  },
  {
    header: "mechanism",
    key: "mechanism",
  },
  {
    header: "Form",
    key: "document_form",
  },
  {
    header: "Country",
    key: "document_country",
  },
  {
    header: "Status",
    key: "status",
  },
];

export interface KycDocumentViewProps {
  id: string;
}

export const KycDocumentView = (props: KycDocumentViewProps) => {
  const { id } = useParams<{ id: string }>();

  const [status, setStatus] = useState<any | null>(null);
  const [document, setDocument] = useState<any | null>(null);

  useEffect(() => {
    api.getKycDocumentStatus(id).then(setStatus);
    api.getKycDocument(id).then(setDocument);
  }, [id]);

  if (document === null) {
    return <Loading />;
  }

  return (
    <div className="transaction-view">
      <h2 className="h3 mb-2 text-gray-800">Kyc Document {uuidLabel(id)}</h2>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Kyc Document">
            <JsonBlob blob={document} />
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <Card label="Kyc Logs">
            <LinkableTable data={status} columns={logsColumns} />
          </Card>
        </div>
      </div>
    </div>
  );
};
