import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { JsonBlob } from "../../components/json-blob";
import { Loading } from "../../components";
import { Column, SystemLog, TransactionLog } from "../../domain";
import { api } from "../../util/api";
import { LinkableTable } from "../../components/linkable-table";

const systemLogColumns: Array<Column> = [
  {
    header: "Primary Event",
    key: "primary_event",
  },
  {
    header: "context Event",
    key: "context_event",
  },
];
export const SystemLogView = () => {
  const [systemLogs, setSystemLogs] = useState<SystemLog[]| null>(null);
  useEffect(() => {
   api.getSystemLogs().then(setSystemLogs)
  }, []);


  return (
    <div className="row">
      <div className="col-lg-12">
      <LinkableTable
                idUrl={`/report/system_logs/:id`}
                idField="id"
                idTruncate={16}
                data={systemLogs}
                columns={systemLogColumns}
              />
      </div>
    </div>
  );
};
