import React, { useState } from "react";
interface ErrorMessageProps {
  title: string;
  messages: any;
  success: boolean;
}
export const ErrorMessage = (props: ErrorMessageProps): React.ReactElement => {
  const { title, messages, success } = props;
  const [display, setDisplay] = useState<string>("inline-block");
  setTimeout(() => {
    setDisplay("none");
  }, 2000);
  window.addEventListener("click", function (e) {
    setDisplay("none");
  });
  return (
    <div
      className={`modal-open modal`}
      style={{
        display: display,
        backgroundColor: "rgba(0, 0, 0, 0)",
        width: "auto",
        height: "auto",
        top: 0,
        right: 0,
      }}
    >
      <div
        className={`modal-md`}
        style={{ width: "400px", margin: "5px 10px auto auto" }}
        role="document"
      >
        <div
          className={`alert ${
            success ? "alert-success" : "alert-danger"
          } alert-dismissable`}
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-hidden="true"
            onClick={() => setDisplay("none")}
          >
            ×
          </button>
          <strong className="mx-2">{title}</strong>
          {success ? (
            <p className="ml-2">{messages[0]}</p>
          ) : (
            <ul>
              {messages.map((msg: string, i: number) => {
                return <li key={i}>{msg}</li>;
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
