

export enum AUTH_ACTIONS {
  REQUEST_LOGIN = "REQUEST_LOGIN",
  RECEIVE_LOGIN = "RECEIVE_LOGIN",
  LOGOUT = "LOGOUT"
}

export interface AuthAction {
  type: AUTH_ACTIONS;
  payload?: any
}

export interface AuthState {
  login: any | null;
}
