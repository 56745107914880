import React, { useState } from "react";
import Select from "react-dropdown-select";
import { FormPopup } from "../../components/form-popup";
import {
  PARTNER_ENVIRONMENT,
  DEPENDENCY,
  DEPENDENCY_ALLOCATION,
} from "../../const/status";
import {
  PartnerConfig,
  PartnerDependency,
  SelectOption,
  Transaction,
} from "../../domain";
import { api } from "../../util/api";
import { CreatePartnerDependencyRequest } from "./types";

interface PartnerDependencyFormProps {
  title: string;
  partner_id: string;
  partnerDependency: PartnerDependency | null;
  onCancel: () => void;
  onSave: () => void;
}
export const PartnerDependencyForm = (props: PartnerDependencyFormProps) => {
  const { onCancel, onSave } = props;
  const [partnerDependency, setPartnerDependency] =
    useState<CreatePartnerDependencyRequest>({
      dependency: props.partnerDependency?.dependency as DEPENDENCY,
      allocation: props.partnerDependency?.allocation as DEPENDENCY_ALLOCATION,
      config: props.partnerDependency?.config || null,
    });
  const handlePartnerDependencyChange =
    (name: any) => (event: { target: { value: any } }) => {
      console.log(partnerDependency);
      setPartnerDependency({
        ...partnerDependency,
        [name]: event.target.value,
      });
    };

  const handleFormClick = async () => {
    if (props.partnerDependency?.id) {
      api
        .putUpdatePartnerDependency(
          props.partner_id,
          props.partnerDependency.id,
          {
            ...partnerDependency,
            dependency: dependency.value,
            allocation: allocation.value,
          } as CreatePartnerDependencyRequest
        )
        .then((result) => {
          onSave();
          onCancel();
        })
        .catch((e) => {
          if (e.response !== undefined && e.response.data !== undefined) {
            setErrorMessage(e.response.data.message);
          }
        });
    } else {
      api
        .postCreatePartnerDependency(props.partner_id, {
          ...partnerDependency,
          dependency: dependency.value,
          allocation: allocation.value,
        } as CreatePartnerDependencyRequest)
        .then((result) => {
          onSave();
          onCancel();
        })
        .catch((e) => {
          if (e.response !== undefined && e.response.data !== undefined) {
            setErrorMessage(e.response.data.message);
          }
        });
    }
  };
  let defaultDependency = 0;
  const DEPENDENCY_OPTIONS: Array<SelectOption> = Object.keys(DEPENDENCY).map(
    (s, index) => {
      if (partnerDependency && partnerDependency.dependency === s) {
        defaultDependency = index;
      }
      return { label: s, value: s };
    }
  );
  let defaultAllocation = 0;
  const ALLOCATION_OPTIONS: Array<SelectOption> = Object.keys(
    DEPENDENCY_ALLOCATION
  ).map((s, index) => {
    if (partnerDependency && partnerDependency.allocation === s) {
      defaultAllocation = index;
    }
    return { label: s, value: s };
  });

  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [dependency, setDependency] = useState<SelectOption>(
    DEPENDENCY_OPTIONS[defaultDependency]
  );
  const [allocation, setAllocation] = useState<SelectOption>(
    ALLOCATION_OPTIONS[defaultAllocation]
  );
  return (
    <FormPopup
      title={props.title}
      onSubmit={handleFormClick}
      onCancel={onCancel}
    >
      <div className="row">
        <div className="col-md-12">
          <label>Dependency</label>
          <Select
            className="form-control"
            values={[dependency]}
            name={"dependency"}
            onChange={(val: any[]) => {
              setDependency(val[0]);
            }}
            options={DEPENDENCY_OPTIONS}
          />
          <label>Allocation</label>
          <Select
            className="form-control"
            values={[allocation]}
            name={"allocation"}
            onChange={(val: any[]) => {
              setAllocation(val[0]);
            }}
            options={ALLOCATION_OPTIONS}
          />
          <label>Config</label>
          <textarea
            name={"config"}
            key={"config"}
            style={{ display: "block", height: 200 }}
            className="form-control"
            value={partnerDependency.config}
            onChange={handlePartnerDependencyChange("config")}
          />
        </div>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    </FormPopup>
  );
};
