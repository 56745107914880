export enum ENTITY_TYPE {
  TRANSACTION = "transaction",
  SETTLEMENT_INSTRUCTION = "settlment-instruction",
  SETTLEMENT_PARAMETER = "settlement-parameter",
  SETTLEMENT_ATTEMPT = "settlement-attempt",
  CONVERSION_ATTEMPT = "conversion-attempt",
}

export const ID_URLS: { [key: string]: string } = {
  partner_id: "/partner/:id",
  partner_config_id: "/partner_config/:id",
  reputation_id: "/reputation/:id",
  kyc_identity_id: "/identity/:id",
  session_id: "/session/:id",
  user_session_id: "/session/:id",
  vendor_id: "/inventory/:id",
};
