import React, { useEffect, useState } from "react";
import { JsonBlob } from "../../components/json-blob";
import { api } from "../../util/api";
import "./style.css";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";

export const PartnerConfigView = () => {
  const [partnerConfig, setPartnerConfig] = useState<any | null>(null);
  let { id } = useParams<{ id: string }>();
  // const { apiKey } = props;
  useEffect(() => {
    api.getPartnerConfigById(id).then(setPartnerConfig);
  }, [id, setPartnerConfig]);

  return (
    <div className="partner-summaries">
      <div className="row">
        <div className="col-md-12">
          <Card label="Statistics">
            <JsonBlob blob={partnerConfig} />
          </Card>
        </div>
      </div>
    </div>
  );
};
