import React, { useState } from "react";
import { JsonBlob } from "../../components";
import { FormPopup } from "../../components/form-popup";
import { copy } from "../../util/copy";

interface SessionLaunchFormProps {
  title: string;
  session: any;
  onCancel: () => void;
}
export const SessionLaunchForm = (props: SessionLaunchFormProps) => {
  const { onCancel, session } = props;

  const handleOpen = () => {
    window.open(session.kyc_form, "_blank");
    onCancel();
  };

  const handleCopy = () => {
    copy(session.kyc_form);
    onCancel();
  };

  return (
    <FormPopup title={props.title} onCancel={onCancel}>
      <div className="row">
        <div className="col-md-12">
          <JsonBlob blob={session} />
        </div>
        <div className="col-md-12">
          <button type="button" onClick={handleOpen}>
            Open in new tab
          </button>
          <button type="button" onClick={handleCopy}>
            Copy to clipboard
          </button>
        </div>
      </div>
    </FormPopup>
  );
};
