import React, { ChangeEvent, Component } from "react";
interface SortableTableHeaderCheckAllProps {
  headerProps: any;
  style: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isCheckAll: boolean;
}
export class SortableTableHeaderCheckAll extends Component<SortableTableHeaderCheckAllProps> {
  static defaultProps = {
    headerProps: {},
  };

  render() {
    return (
      <td style={this.props.style} {...this.props.headerProps}>
        <input
          type="checkbox"
          id="checkAll"
          name="checkAll"
          onChange={this.props.onChange}
          checked={this.props.isCheckAll}
        />
      </td>
    );
  }
}
