import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export interface CardProps {
  label: string;
  children: ReactNode;
  styleName?: string;
  link?: string;
}

// const CardDropdown = () => {
//   return (
//     <div className="dropdown no-arrow">
//       <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//         <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
//       </a>
//       <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
//         <div className="dropdown-header">Dropdown Header:</div>
//         <a className="dropdown-item" href="#">Action</a>
//         <a className="dropdown-item" href="#">Another action</a>
//         <div className="dropdown-divider"></div>
//         <a className="dropdown-item" href="#">Something else here</a>
//       </div>
//     </div>
//   )
// }

export const Card = (props: CardProps) => {
  const { styleName } = props;
  return (
    <div
      style={{ width: "100%" }}
      className={classNames("card shadow mb-4", {
        [`border-${styleName}`]: styleName !== undefined,
      })}
    >
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        {props.link !== undefined ? (
          <Link
            className="nav-link"
            to={props.link}
            style={{ textDecoration: "underline" }}
          >
            <i className="fas fa-share"></i>
            <h6
              className="m-0 font-weight-bold text-primary"
              style={{ display: "inline" }}
            >
              {props.label}
            </h6>
          </Link>
        ) : (
          <h6
            className={classNames("m-0 font-weight-bold text-primary", {
              [`text-${styleName}`]: styleName !== undefined,
            })}
          >
            {props.label}
          </h6>
        )}
      </div>

      <div className="card-body">{props.children}</div>
    </div>
  );
};
