import React, { useState } from "react";
import { FormPopup } from "../../components/form-popup";
import { AdminGroup } from "../../domain";
import { api } from "../../util/api";
import { CreateGroupRequest } from "./types";

interface GroupFormProps {
  title: string;
  adminGroup: AdminGroup | null;
  onCancel: () => void;
  onSave: () => void;
}
export const GroupForm = (props: GroupFormProps) => {
  const { onCancel, onSave } = props;
  const [adminGroup, setAdminGroup] = useState<CreateGroupRequest>({
    label: props.adminGroup?.label || "",
  });
  const handleGroupChange =
    (name: any) => (event: { target: { value: any } }) => {
      setAdminGroup({
        ...adminGroup,
        [name]: event.target.value,
      });
    };

  const handleFormClick = async () => {
    if (props.adminGroup?.id) {
      api
        .putUpdateGroup(props.adminGroup.id, {
          ...adminGroup,
        } as CreateGroupRequest)
        .then((result) => {
          onSave();
          onCancel();
        });
    } else {
      api
        .postCreateGroup({
          ...adminGroup,
        } as CreateGroupRequest)
        .then((result) => {
          onSave();
          onCancel();
        });
    }
  };

  return (
    <FormPopup
      title={props.title}
      onSubmit={handleFormClick}
      onCancel={onCancel}
    >
      <div className="row">
        <div className="col-md-12">
          <label>Label</label>
          <input
            type="text"
            name={"label"}
            key={"label"}
            style={{ display: "block" }}
            className="form-control"
            value={adminGroup.label}
            onChange={handleGroupChange("label")}
          />
        </div>
      </div>
    </FormPopup>
  );
};
