import React, { useEffect, useState } from "react";
import "./style.css";
import { Card } from "../../components/card";
import { randStr } from "../../util/helpers";
import {
  ComposeForm,
  ComposeFormField,
  FIELD_TYPE,
} from "../../components/compose-form";
import Axios from "axios";
import { api } from "../../util/api";
import { LinkableTable } from "../../components/linkable-table";
import { JsonBlob } from "../../components";
import { PARTNER_ENVIRONMENT } from "../../const";

export const TestFtxView = () => {
  const [form, setForm] = useState<any>({
    tenant: "",
    clientUserId: "",
  });

  const [result, setResult] = useState<any>(undefined);

  const handleSubmit = () => {
    setResult(null);
    return api.benchtestFtx(form).then(setResult);
  };

  const formFields: Array<ComposeFormField> = [
    {
      label: "Tenant",
      id: "tenant",
    },
    {
      label: "Client User Id",
      id: "clientUserId",
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Test Ftx">
            <ComposeForm
              fields={formFields}
              value={form}
              onChange={setForm}
              onSubmit={handleSubmit}
            />
          </Card>
        </div>
      </div>
      {result !== undefined ? (
        <div className="row">
          <div className="col-lg-12">
            <Card label="Test">
              <JsonBlob blob={result} />
            </Card>
          </div>
        </div>
      ) : null}
    </div>
  );
};
