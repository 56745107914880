import { AuthAction, AuthState, AUTH_ACTIONS } from "./types";

export * from "./types";
export * from "./actions";

const defaultState: AuthState = {
  login: null,
};

export const authReducer = (
  state: AuthState = defaultState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.RECEIVE_LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case AUTH_ACTIONS.LOGOUT:
      return {
        ...state,
        login: null,
      };

    default:
      return state;
  }
};
