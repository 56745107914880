import React, { useEffect, useState } from "react";
import { Card } from "../../components/card";
import { LinkableTable } from "../../components/linkable-table";
import { AdminAuth, Column } from "../../domain";
import { api } from "../../util/api";

const userColumns: Array<Column> = [
  {
    header: "Name",
    key: "full_name",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Group",
    key: "group_label",
  },
];
export const UsersView = () => {
  const [users, setUsers] = useState<Array<AdminAuth> | null>(null);
  useEffect(() => {
    api.getUsers().then((u: AdminAuth[]) => {
      setUsers(u);
    });
  }, []);
  return (
    <div className="partner-summaries">
      <div className="row">
        <div className="col-md-12">
          <Card label={`Admin Users`}>
            <LinkableTable
              idUrl="/admin/user/:id"
              idField="id"
              data={users}
              columns={userColumns}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
