import React from "react";
import { Link } from "react-router-dom";
import { uuidLabel } from "../util/labels";

export interface IdLinkProps {
  id: string | undefined | null;
  url: string;
  truncate?: number;
}

export const IdLink = (props: IdLinkProps) => {
  const { id, url, truncate } = props;
  if (id === undefined || id == null) {
    return null;
  }
  return (
    <Link style={{ display: "block" }} to={url.replace(":id", id)}>
      <i className="fas fa-external-link-alt"></i>{" "}
      {truncate !== undefined ? id.substring(0, truncate) : id}
    </Link>
  );
};
