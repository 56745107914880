import * as _ from "lodash";
import { SelectOption } from "../domain";
export const defaultValueOption = (
  options: Array<SelectOption>,
  value: any
): number => {
  return Math.max(
    _.findIndex(options, function (o) {
      return o.value.toLowerCase() === value + "";
    }),
    0
  );
};

export const generateSelectOptions = (
  options: Array<any>,
  filed?: string
): Array<SelectOption> => {
  return options.map((item) => {
    const s = filed ? item[filed] : item;
    return {
      label: s,
      value: s,
    };
  });
};

export const rand = (max: number, min: number = 0) => {
  if (min > max) {
    const tmp = min;
    min = max;
    max = tmp;
  }
  return Math.random() * (max - min) + min;
};

export const randInt = (max: number, min: number = 0) => {
  return Math.floor(rand(max, min));
};

const STRCHARS = "abcdefghijklmnopqrstuvwxyz1234567890";
export const randStr = (length: number = 10, chars: string = STRCHARS) => {
  let s = "";
  for (let i = 0; i < length; i++) {
    s += chars[randInt(chars.length)];
  }
  return s;
};
