import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";
import { JsonBlob } from "../../components/json-blob";
import { PartnerDependency } from "../../domain";
import { api } from "../../util/api";
import { PartnerDependencyForm } from "./partner-dependency-form";

export const PartnerDependencyView = () => {
  let { partner_id } = useParams<{ partner_id: string }>();
  let { id } = useParams<{ id: string }>();
  const [dependency, setDependency] = useState<PartnerDependency | null>(null);
  const [updateDependencyForm, setUpdateDependencyForm] =
    useState<boolean>(false);
  const [onSaveDependencyForm, setOnSaveDependencyForm] =
    useState<boolean>(false);
  useEffect(() => {
    api.getPartnerDependency(partner_id, id).then((pd: PartnerDependency) => {
      setDependency(pd);
    });
    if (onSaveDependencyForm) {
      setOnSaveDependencyForm(false);
    }
  }, [partner_id, id, onSaveDependencyForm]);
  return (
    <div className="partner-summaries">
      {updateDependencyForm && (
        <PartnerDependencyForm
          title="Update a dependency"
          partner_id={partner_id}
          partnerDependency={dependency}
          onCancel={() => setUpdateDependencyForm(false)}
          onSave={() => setOnSaveDependencyForm(true)}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <Card label={`Dependency: ${dependency?.dependency}`}>
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setUpdateDependencyForm(true);
                  }}
                >
                  Edit dependency
                </button>
              </div>
            </div>
            <JsonBlob blob={dependency} />
          </Card>
        </div>
      </div>
    </div>
  );
};
