import React, { useEffect, useState } from "react";
import { Column, Transaction } from "../../domain";
import "./style.css";
import { api } from "../../util/api";
import { Loading } from "../../components";
import { LinkableTable } from "../../components/linkable-table";
import { JsonBlob } from "../../components/json-blob";
import { useParams } from "react-router-dom";
import { Card } from "../../components/card";

export interface SessionViewProps {
  id: string;
}

export interface SessionViewState {
  transactions: Array<Transaction> | null;
  session: any | null;
}

const logsColumns: Array<Column> = [
  {
    header: "Primary event",
    key: "primary_event",
  },
  {
    header: "Context event",
    key: "context_event",
  },
];

export const SessionView = (props: SessionViewProps) => {
  const { id } = useParams<{ id: string }>();
  const [session, setSession] = useState<any | null>(null);
  const [logs, setLogs] = useState<Array<Transaction> | null>(null);
  const [result, setResult] = useState<any>(undefined);

  useEffect(() => {
    api.getSession(id).then(setSession);

    api.getSessionLogs(id).then(setLogs);
  }, []);

  if (session === null) {
    return <Loading />;
  }

  const handleOpenSession = () => {
    api.getSessionHandoff(id).then((response) => {
      window.open(response.kyc_form, "_blank");
    });
  };

  const handleFetchLoginStatus = () => {
    setResult(null);
    api.getSessionLoginStatus(id).then(setResult);
  };

  const handleFetchKycHistory = () => {
    setResult(null);
    api.getSessionKycHistory(id).then(setResult);
  };

  const handleFtxPull = () => {
    setResult(null);
    api.getSessionFtxPull(id).then(setResult);
  };

  const handleFtxPullToForce = (force?: boolean) => {
    setResult(null);
    api.getSessionFtxPull(id, force).then(setResult);
  };

  const handleFtxPush = () => {
    setResult(null);
    api.getSessionFtxPush(id).then(setResult);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Card label="Session">
            <button onClick={handleOpenSession} type="button">
              Open Session
            </button>
            <button onClick={handleFetchLoginStatus} type="button">
              Fetch login_status
            </button>
            <button onClick={handleFetchKycHistory} type="button">
              Fetch kyc_history
            </button>
            <button onClick={handleFtxPull} type="button">
              FTX Pull
            </button>
            <button onClick={() =>handleFtxPullToForce(true)} type="button">
              FTX Pull To Force
            </button>
            <button onClick={handleFtxPush} type="button">
              FTX Push
            </button>
            <JsonBlob blob={session} />
          </Card>
        </div>
      </div>
      {result !== undefined ? (
        <div className="row">
          <div className="col-lg-12">
            <Card label="Query Result">
              <JsonBlob blob={result} />
            </Card>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-xl-12">
          <Card label={"Logs"}>
            <LinkableTable
              idUrl="/reputation/log/:id"
              data={logs}
              columns={logsColumns}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
