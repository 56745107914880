import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { JsonBlob } from "../../components/json-blob";
import { LinkableTable } from "../../components/linkable-table";
import { TRANSACTION_STATUS } from "../../const/status";
import {
  PartnerStatistics,
  SelectOption,
  PartnerDependency,
  Column,
  PartnerConfig,
  Partner,
} from "../../domain";
import { Transaction } from "../../domain/transaction";
import { api } from "../../util/api";
import { METHOD_EVENT, TRANSACTION_EVENT } from "../../const/events";
import "./style.css";
import { useParams } from "react-router-dom";
import { ONE_DAY, ONE_HOUR, REPUTATION_COLUMNS } from "../../const";
import { Card } from "../../components/card";
import { PartnerForm } from "./partner-form";
import { Loading } from "../../components/loading";
import { PartnerDependencyForm } from "../partner-dependency-view/partner-dependency-form";

export interface PartnerSummariesProps {
  apiKey: string;
}
// hack for shitty library
window.React = React;

const CreatedAtSorter = {
  desc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at < _b.created_at) {
        return 1;
      }
      if (_a.created_at > _b.created_at) {
        return -1;
      }
      return 0;
    });
  },

  asc: (data: Array<Transaction>, key?: string): Array<Transaction> => {
    return data.sort((_a, _b) => {
      if (_a.created_at > _b.created_at) {
        return 1;
      }
      if (_a.created_at < _b.created_at) {
        return -1;
      }
      return 0;
    });
  },
};

const NONE = "-";

const STATUS_OPTIONS = [
  { label: "-", value: NONE },
  ...Object.keys(TRANSACTION_STATUS).map(
    (s: string): SelectOption => ({
      value: s,
      label: s,
      filter: s,
    })
  ),
];

const DATE_RANGE = [
  {
    label: "Last 7 days",
    value: "last_7_days",
    window_ms: ONE_HOUR,
  },
  {
    label: "Last 14 days",
    value: "last_14_days",
    window_ms: ONE_DAY,
  },
  {
    label: "Last 30 days",
    value: "last_30_days",
    window_ms: ONE_DAY,
  },
  {
    label: "Month to date",
    value: "month_to_date",
    window_ms: ONE_DAY,
  },
  {
    label: "Last month",
    value: "last_month",
    window_ms: ONE_DAY,
  },
];

const dependenciesColumns: Array<Column> = [
  {
    header: "Dependency",
    key: "dependency",
  },
  {
    header: "Allocation",
    key: "allocation",
  },
];
const getMinTs = (filter: string): number | undefined => {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  switch (filter) {
    case "last_7_days":
      return now.getTime() - 7 * ONE_DAY;
    case "last_14_days":
      return now.getTime() - 14 * ONE_DAY;
    case "last_30_days":
      return now.getTime() - 30 * ONE_DAY;
    case "month_to_date":
      now.setDate(1);
      return now.getTime();
    case "last_month":
      now.setDate(1);
      now.setMonth(now.getMonth() - 1);
      return now.getTime();
  }
  return undefined;
};

const getMaxTs = (filter: string): number | undefined => {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  switch (filter) {
    case "last_month":
      now.setDate(1);
      return now.getTime();
  }
  return undefined;
};

const TRANSACTION_EVENT_OPTIONS = [
  { label: "-", value: NONE },
  ...Object.keys(TRANSACTION_EVENT).map((e) => ({
    label: e,
    value: e,
    filter: e,
  })),
];

const METHOD_EVENT_OPTIONS = [
  { label: "-", value: NONE },
  ...Object.keys(METHOD_EVENT).map((e) => ({
    label: e,
    value: e,
    filter: e,
  })),
];

export const PartnerView = (props: PartnerSummariesProps) => {
  const [partner, setPartner] = useState<Partner | null>(null);
  const [partnerConfig, setPartnerConfig] = useState<PartnerConfig | null>(
    null
  );
  const [statistics, setStatistics] = useState<PartnerStatistics | null>(null);

  const [reputations, setReputations] = useState<Array<any> | null>(null);
  const [apiKeys, setApiKeys] = useState<Array<any> | null>(null);
  const [dateRange, setDateRange] = useState<SelectOption>(DATE_RANGE[0]);
  const [transactionEvent, setTransctionEvent] = useState<SelectOption>(
    TRANSACTION_EVENT_OPTIONS[0]
  );
  const [methodEvent, setMethodEvent] = useState<SelectOption>(
    METHOD_EVENT_OPTIONS[0]
  );
  const [includeAbandoned, setIncludeAbandoned] = useState(false);
  const [onOpenCreatePartnerForm, setOnOpenCreatePartnerForm] =
    useState<boolean>(false);
  const [onSavePartnerForm, setOnSavePartnerForm] = useState<boolean>(false);
  const [dependencies, setDependencies] =
    useState<Array<PartnerDependency> | null>(null);
  const [createDependencyForm, setCreateDependencyForm] =
    useState<boolean>(false);
  const [onSaveDependencyForm, setOnSaveDependencyForm] =
    useState<boolean>(false);
  let { id } = useParams<{ id: string }>();
  // const { apiKey } = props;
  useEffect(() => {
    api.getPartnerStatistics(id).then(setStatistics);
  }, [id, setStatistics]);
  useEffect(() => {
    api.getPartner(id).then((response) => {
      setPartner(response);
      if (onSavePartnerForm) {
        setOnSavePartnerForm(false);
        setOnOpenCreatePartnerForm(false);
      }
    });
    api.getPartnerConfig(id).then((response) => {
      setPartnerConfig({
        ...response,
        currencies: " - truncated - ",
      });
    });
    api.getPartnerApiKeys(id).then(setApiKeys);
  }, [id, setPartner, onSavePartnerForm]);

  const include_abandoned = includeAbandoned ? "true" : "false";
  const has_transaction_event =
    transactionEvent.filter !== undefined
      ? transactionEvent.filter[0]
      : undefined;
  const has_method_event =
    methodEvent.filter !== undefined ? methodEvent.filter[0] : undefined;
  const date_range = dateRange.value;

  const min_ts = getMinTs(date_range);
  const max_ts = getMaxTs(date_range);
  const windowMs = (dateRange as any).window_ms;

  useEffect(() => {
    setReputations(null);
    api
      .getPartnerReputations(id, {
        min_ts,
        max_ts,
      })
      .then((ts: Array<Transaction>) =>
        setReputations(CreatedAtSorter.desc(ts))
      );
  }, [include_abandoned, has_transaction_event, has_method_event, date_range]);

  useEffect(() => {
    api.getPartnerDependencies(id).then((pd: Array<PartnerDependency>) => {
      setDependencies(pd);
    });
    if (onSaveDependencyForm) {
      setOnSaveDependencyForm(false);
    }
  }, [id, onSaveDependencyForm]);

  return (
    <div className="partner-summaries">
      {onOpenCreatePartnerForm && (
        <PartnerForm
          title="Edit partner"
          partnerConfig={partnerConfig}
          onCancel={() => setOnOpenCreatePartnerForm(false)}
          onSave={() => setOnSavePartnerForm(true)}
        />
      )}
      {createDependencyForm && (
        <PartnerDependencyForm
          title="Create a new dependency"
          partner_id={`${partner?.id}`}
          partnerDependency={null}
          onCancel={() => setCreateDependencyForm(false)}
          onSave={() => setOnSaveDependencyForm(true)}
        />
      )}
      <div className="row">
        <div className="col-md-12">
          <Card
            label={`Partner: ${
              partnerConfig !== null ? partnerConfig.label : props.apiKey
            }`}
          >
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    setOnOpenCreatePartnerForm(true);
                  }}
                >
                  Edit partner
                </button>
              </div>
            </div>
            <JsonBlob blob={partnerConfig} />
          </Card>
        </div>
        <div className="col-md-12">
          <Card label="Statistics">
            <JsonBlob blob={statistics} />
          </Card>
        </div>

        <div className="col-md-12">
          <Card label={"Api Keys"}>
            {apiKeys === null ? (
              <Loading />
            ) : (
              <LinkableTable
                columns={[
                  {
                    header: "Public Api Key",
                    key: "public_api_key",
                  },
                ]}
                data={apiKeys}
              />
            )}
          </Card>
        </div>
        <div className="col-md-12">
          <Card label={"Dependencies"}>
            <div className="mb-4">
              <button
                className="btn btn-dark"
                onClick={() => {
                  setCreateDependencyForm(true);
                }}
              >
                Add dependency
              </button>
            </div>
            {dependencies === null ? (
              <Loading />
            ) : (
              <LinkableTable
                idUrl={`/partner/${partner?.id}/dependency/:id`}
                idField="id"
                idTruncate={16}
                data={dependencies}
                columns={dependenciesColumns}
              />
            )}
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Card label={"Reputations"}>
            <div className="row mb-4">
              <div className="col-md-4">
                <label>Date Range</label>
                <Select
                  className="form-control"
                  values={[dateRange]}
                  onChange={(val: any[]) => {
                    setDateRange(val[0]);
                  }}
                  options={DATE_RANGE}
                />
              </div>
              <div className="col-md-4">
                <label>Include Abandoned</label>
                <input
                  type="checkbox"
                  checked={includeAbandoned}
                  style={{ display: "block" }}
                  onChange={() => {
                    setIncludeAbandoned(!includeAbandoned);
                  }}
                />
              </div>
            </div>
            <LinkableTable
              idUrl="/reputation/:id"
              data={reputations}
              columns={REPUTATION_COLUMNS}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};
